import * as TWEEN from 'es6-tween';
import * as THREE from 'three';

import candleTextureImage from 'assets/textures/candle.jpg';

import { CityProps } from '../../types';
import { colorScale, coordinatesToPosition, sizeScaleLog } from '../../utils';
import candleShader from '../shaders/candleShader';

class Candles {
  group: THREE.Group;

  constructor(p: { candleHeight: number; globeRadius: number; scene: THREE.Scene; cities: CityProps[] }) {
    this.group = new THREE.Group();
    const loader = new THREE.TextureLoader();
    const texture = loader.load(candleTextureImage);
    texture.wrapS = texture.wrapT = THREE.RepeatWrapping;

    const uniforms = {
      u_time: {
        value: 0,
      },
      u_index: 0,
    };

    p.cities.forEach((city: CityProps) => {
      const { capacity, utilization, id, name } = city;

      const unitRadius = 1.5;
      const candleSize: number = sizeScaleLog(p.cities, p.candleHeight * 0.01, p.candleHeight * 0.5)(capacity);

      const geometry = new THREE.CylinderBufferGeometry(unitRadius * 0.4, unitRadius * 0.4, candleSize, 6, 1, true);
      const material = new THREE.ShaderMaterial({
        uniforms: {
          ...uniforms,
          u_texture: {
            value: texture,
          },
          u_color: {
            value: new THREE.Color(colorScale(utilization)),
          },
          u_index: {
            value: Math.random(),
          },
        },
        vertexShader: candleShader.vertexShader,
        fragmentShader: candleShader.fragmentShader,
        transparent: true,
        side: THREE.DoubleSide,
        // alphaTest: 0.9,
        depthTest: true,
        depthWrite: false,
      });
      const emptyMaterial = new THREE.MeshBasicMaterial({ transparent: true, opacity: 0 });

      const candle: THREE.Mesh = new THREE.Mesh(
        geometry,
        // To prevent top and bottom sides getting rendered.
        [material, emptyMaterial, emptyMaterial]
      );
      // Move the marker to the coordinates from the marker object, while moving
      // the z axis to be on top of the surface.
      const pos = p.globeRadius + candleSize * 0.5;

      if (isNaN(pos)) {
        return;
      }

      candle.position.copy(coordinatesToPosition([city.lng, city.lat], pos));
      candle.lookAt(new THREE.Vector3(0, 0, 0));
      candle.rotateX(Math.PI * 0.5);

      // send only the type, id & name, so the external script to retrieve the update data
      // directly from the store
      candle.userData.data = { type: 'city', id, name };

      candle.name = name;

      this.group.add(candle);
    });
  }

  animationLoop(p: { clock: THREE.Clock; camera: THREE.Camera; controls: any }) {
    const time = p.clock.getElapsedTime();
    const vectors: THREE.Vector3[][] = [];
    this.group.children.forEach((candle) => {
      (candle as any).material[0].uniforms.u_time.value = time;
      vectors.push([candle.position, candle.position.clone().project(p.camera)]);
    });
  }

  appear() {
    this.group.scale.set(0, 0, 0); // keep the markers hidden until the scene animation is done
    new TWEEN.Tween({ k: 0 })
      .to({ k: 1 }, 3000)
      .easing(TWEEN.Easing.Quintic.Out)
      .on('update', ({ k }) => this.group.scale.set(k, k, k))
      .start();
  }
}

export default Candles;
