import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import { createLoader, createMiddleware } from 'redux-storage';
import createEngine from 'redux-storage-engine-indexed-db';
import thunkMiddleware from 'redux-thunk';

import { appRefreshMiddleware, dataMiddleWare, errorMiddleware } from './middlewares';
import rootReducer from './structure/rootReducer';
import { StateProps } from './structure/types';

const engine = createEngine('akamai');
export const load = createLoader(engine);

export default function configureStore(initialState?: StateProps) {
  const middlewares = [
    // Add other middleware on this line...
    // Redux middleware that spits an error on you when you try to mutate your state either inside a dispatch or between dispatches.
    reduxImmutableStateInvariant(),

    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
    thunkMiddleware,

    errorMiddleware,
    dataMiddleWare,
    appRefreshMiddleware,
    createMiddleware(
      engine,
      [],
      [
        // cache only these actions
        'SETTINGS_TOGGLE_DEMO',
        // 'SETTINGS_TOGGLE_CHECKBOX',
        // 'SETTINGS_TOGGLE_RADIO',
        'SETTINGS_SET_RANGE_INPUT',
      ]
    ),
  ];

  return createStore(rootReducer(), initialState, composeWithDevTools(applyMiddleware(...middlewares)));
}
