import { PerspectiveCamera, Scene, WebGLRenderer } from 'three';
// import { Interaction } from 'three.interaction';

export interface MandatoryEntryPointProps {
  readonly el: HTMLDivElement;
}

export interface WithThreeEntryPointProps extends MandatoryEntryPointProps {
  init: () => void;
  destroy: () => void;
  // renderScene: () => void;
  renderer: WebGLRenderer;
  camera: PerspectiveCamera;
  scene: Scene;
}

export function withEntryPoint<In, Out>(fn: (props: In & WithThreeEntryPointProps) => Out) {
  return (props: In & MandatoryEntryPointProps) => {
    const { el } = props;

    const renderer = new WebGLRenderer({ antialias: true });
    const camera = new PerspectiveCamera();
    const scene = new Scene();
    // new Interaction(renderer, scene, camera);

    // const renderScene = () => {
    //   renderer.render(scene, camera);
    // };

    const handleResize = () => {
      const { clientWidth, clientHeight } = el;
      renderer.setSize(clientWidth, clientHeight);
      camera.aspect = clientWidth / clientHeight;
      camera.updateProjectionMatrix();
      // renderScene();
    };

    const init = () => {
      // Create the Canvas and apply it to the Ref
      el.appendChild(renderer.domElement);
      // Update the Canvas Size on Window Resize
      window.addEventListener('resize', handleResize);
      // Apply the default scene & camera settings
      handleResize();
    };

    const destroy = () => {
      window.removeEventListener('resize', handleResize);
      renderer.dispose();
      el.removeChild(renderer.domElement);
    };

    return fn({ ...props, init, destroy, renderer, camera, scene });
  };
}
