import * as THREE from 'three';

import { coordinatesToPosition } from '../../utils';

import './continent-markers.scss';

class ContinentMarkers {
  container: THREE.Group;
  spheres: THREE.Mesh[];
  renderer: THREE.Renderer;
  camera: THREE.Camera;
  globeRadius: number;
  lineHeight: number;

  constructor(props: {
    continents: any[];
    globeRadius: number;
    lineHeight: number;
    renderer: THREE.Renderer;
    camera: THREE.Camera;
    onClickMarker: (continent: any) => void;
  }) {
    const { continents, globeRadius, lineHeight, renderer, camera } = props;
    const sphereMaterial = new THREE.MeshBasicMaterial({
      color: 0xffffff,
      transparent: true,
      opacity: 0,
    });

    this.camera = camera;
    this.renderer = renderer;
    this.container = new THREE.Group();
    this.spheres = [];
    this.globeRadius = globeRadius;
    this.lineHeight = lineHeight;

    document.getElementById('globe-continent-markers')?.remove();
    const el = document.createElement('div');
    el.id = 'globe-continent-markers';
    this.renderer.domElement.parentElement?.appendChild(el);
    const strokeColors = ['#29DDCA', '#FF9933', '#1B9FFF'];

    for (const s in continents) {
      const continent = continents[s];
      const point = coordinatesToPosition([continent.lng, continent.lat], globeRadius + lineHeight);

      const sphereGeometry = new THREE.SphereBufferGeometry(1, 5, 5);
      const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
      sphere.position.copy(point);
      sphere.userData.continentId = continent.id;
      this.spheres.push(sphere);
      this.container.add(sphere);

      const node = document.createElement('div');
      node.innerHTML = `<div id="continent-marker-${continent.id}" class="continent-marker">
          <div class="svg-wrapper">
            <svg style="top: -15px; left: -15px; width: 30px; height: 30px">
              <circle
                class="outer-circle"
                cx="15" cy="15" r="13"
                stroke="${strokeColors[parseInt(s) % strokeColors.length]}" stroke-width="1px"
                fill="rgba(1, 7, 19, 0.5)"
              "/>
              <circle
                class="inner-circle"
                cx="15" cy="15" r="${2}"
                fill="#fff"
              "/>
            </svg>
          </div>
          <h3>${continent.name.toLocaleLowerCase()}</h3>
      </div>`;

      const innerNode = node.firstChild;
      el.appendChild(innerNode as any);
      innerNode?.addEventListener('mousedown', (e) => {
        e.stopPropagation();
        props.onClickMarker(continent);
      });
    }
  }

  updateMarkers() {
    const widthHalf = this.renderer.domElement.clientWidth * 0.5;
    const heightHalf = this.renderer.domElement.clientHeight * 0.5;
    const cameraDistanceToCenter = this.camera.position.distanceTo(new THREE.Vector3(0, 0, 0));
    const occlusionOffset = this.globeRadius + this.lineHeight;
    const checkDistance = Math.sqrt(
      cameraDistanceToCenter * cameraDistanceToCenter + occlusionOffset * occlusionOffset
    );
    // const checkDistance = cameraDistanceToCenter;
    this.spheres.forEach((sphere) => {
      const pos = sphere.position.clone();

      pos.project(this.camera);
      pos.x = pos.x * widthHalf + widthHalf;
      pos.y = -(pos.y * heightHalf) + heightHalf;
      const marker = document.getElementById(`continent-marker-${sphere.userData.continentId}`);

      const cameraDistanceToObject = this.camera.position.distanceTo(sphere.position);
      const opacity = (checkDistance - cameraDistanceToObject + 100) / 100;

      if (marker) {
        marker.style.left = `${pos.x}px`;
        marker.style.top = `${pos.y}px`;
        marker.style.opacity = Math.max(opacity, 0.05).toString();
      }
    });
  }

  setVisibility(value: boolean) {
    const container = document.getElementById('globe-continent-markers');
    if (container) {
      container.style.opacity = value ? '1' : '0';
    }
  }
}

export default ContinentMarkers;
