import * as THREE from 'three';
import { Object3D } from 'three';

class Lights {
  container: THREE.Object3D;
  ambientLight: THREE.AmbientLight;
  earthLights: THREE.Light[];

  constructor(scene: THREE.Scene) {
    this.container = new Object3D();
    this.ambientLight = new THREE.AmbientLight(0xffffff);
    this.earthLights = [
      new THREE.DirectionalLight(0x93dfff, 2.2),
      new THREE.DirectionalLight(0x93dfff, 2.2),
      new THREE.DirectionalLight(0x93dfff, 2.2),
      new THREE.DirectionalLight(0x93dfff, 2.2),
    ];

    this.earthLights[0].position.set(100, -90, -90);
    this.earthLights[0].lookAt(0, 0, 0);
    this.earthLights[1].position.set(100, -30, -90);
    this.earthLights[1].lookAt(0, 0, 0);
    this.earthLights[2].position.set(-100, 90, -90);
    this.earthLights[2].lookAt(0, 0, 0);
    this.earthLights[3].position.set(-100, 30, -90);
    this.earthLights[3].lookAt(0, 0, 0);

    this.earthLights.forEach((light) => {
      this.container.add(light);
      // const helper = new THREE.DirectionalLightHelper(light);
      // this.container.add(helper);
    });
    scene.add(this.container);
  }

  animationLoop(camera: THREE.Camera) {
    this.container.lookAt(camera.position);
  }
}

export default Lights;
