import produce from 'immer';

import { actionTypes } from './actions';
import { ChartState } from './types';

export const initialState: ChartState = {
  visible: false,
  dbDataLoaded: false,
  info: {},
};

export default produce((draft, action) => {
  switch (action.type) {
    case actionTypes.toggleChartVisibility:
      draft.visible = typeof action.payload !== 'undefined' ? action.payload : !draft.visible;
      break;
    case actionTypes.loadChartInfo:
      draft.info = action.payload;
      break;
    case actionTypes.requestDBData:
      draft.dbDataLoaded = false;
      break;
    case actionTypes.successDBData:
      draft.dbDataLoaded = true;
      break;
    case actionTypes.errorDBData:
      draft.dbDataLoaded = false;
      break;
  }
}, initialState);
