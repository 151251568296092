import mapboxgl from 'mapbox-gl';

const MAPBOX_STYLE_URL = 'mapbox://styles/ahokinso/ckgma6am410lv19mh6651dioy';
const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoiYWhva2luc28iLCJhIjoiY2pqcHAyMzZpMm9zNTNyc29jcWF0bGx5MyJ9.x8WxFfRAngGajhdyYq45mQ';
const MAP_MAX_ZOOM = 10;

export interface MandatoryEntryPointProps {
  readonly el: HTMLDivElement;
  zoom?: number;
}

export interface WithMapBoxEntryProps extends MandatoryEntryPointProps {
  map: mapboxgl.Map;
  destroy: () => void;
}

export function withEntryPoint<In, Out>(fn: (props: In & WithMapBoxEntryProps) => Out) {
  return (props: In & MandatoryEntryPointProps) => {
    const { el } = props;

    mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;
    const map: mapboxgl.Map = new mapboxgl.Map({
      container: el,
      style: MAPBOX_STYLE_URL,
      center: [0, 0],
      zoom: props.zoom ? props.zoom : 0,
      maxZoom: MAP_MAX_ZOOM,
    });

    const destroy = () => {
      map.remove();
    };

    return fn({ ...props, map, destroy });
  };
}
