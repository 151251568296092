export const GLOBE_SWITCH_POINT = 270;
export const GLOBE_SWITCH_FALLOFF = 10;
export const MAP_SWITCH_POINT = 3;
export const MAP_SWITCH_FALLOFF = 0.1;
export const WEB_CANDLES_LIMIT = 500;

export const ZOOM_LEVELS = {
  globe: {
    map: 2,
    globe: 280,
  },
  map: {
    map: 4.0,
    globe: 100,
  },
  chart: {
    map: 7.0,
    globe: 100,
  },
};
