import CameraControls from 'camera-controls';
import * as TWEEN from 'es6-tween';
import Stats from 'stats.js';
import * as THREE from 'three';
import { Vector3 } from 'three';

import { dataDb } from 'api/dbs';
import { GlobeState } from 'store/structure/GlobeMap/types';
import { withEntryPoint } from 'utils/ThreeEntryPoint';

import { ArcAttack, CityProps, GlobeOutProps, GlobeProps } from '../types';
import { coordinatesToPosition, positionToCoordinates } from '../utils';
import Arcs from './components/Arcs';
import Candles from './components/Candles';
import CodeWall from './components/CodeWall';
import ContinentMarkers from './components/ContinentMarkers';
import Earth from './components/Earth';
import Lights from './components/Lights';
import PostProcessing from './components/PostProcessing';
import {
  AUTO_ROTATION_COEFFICIENT,
  GLOBE_BACKGROUND_COLOR,
  GLOBE_CAMERA_POSITION_Z,
  GLOBE_RADIUS,
  GLOBE_SEGMENTS,
  ROTATION_COEFFICIENT,
} from './constants';

export const Globe = withEntryPoint<GlobeProps, GlobeOutProps>((props) => {
  const { renderer, camera, scene, updateCameraCb } = props;
  CameraControls.install({ THREE: THREE });

  const controls = new CameraControls(camera, renderer.domElement);
  const stats = new Stats();
  const clock = new THREE.Clock();
  const centerVector = new Vector3(0, 0, 0);
  let frameId: number | null;
  let candles: Candles | null;
  let arcs: Arcs | null;
  let continentMarkers: ContinentMarkers;
  let earth: Earth;
  let codeWall: CodeWall;
  let lights: Lights;
  let postProcessing: PostProcessing;

  let pointRays = true;
  let connectPointsWithArcs = true;
  let autoRotate = true;

  scene.background = new THREE.Color(GLOBE_BACKGROUND_COLOR);

  const configureCamera = (initState: GlobeState) => {
    controls.minDistance = 100;
    controls.maxDistance = 400;
    controls.touches.two = CameraControls.ACTION.TOUCH_ROTATE;
    controls.touches.two = CameraControls.ACTION.TOUCH_DOLLY;
    controls.setLookAt(0, 0, GLOBE_CAMERA_POSITION_Z, 0, 0, 0, false);
    setCameraPosFromLatLng({
      ...initState,
      suppressTransition: true,
    });
    camera.updateProjectionMatrix();
    (controls as any).addEventListener('update', handleControlsChange);
  };

  const handleControlsChange = () => {
    // https://stackoverflow.com/questions/46625231/convert-mouse-click-to-latitude-and-longitude-on-rotated-sphere
    const [lng, lat] = positionToCoordinates(camera.position);
    updateCameraCb({
      distance: getZoom(),
      lng,
      lat,
    });
  };

  const setCameraPosFromLatLng = (params: {
    lat: number;
    lng: number;
    zoom?: number;
    targetPos?: THREE.Vector3;
    suppressTransition?: boolean;
  }) => {
    const pos = coordinatesToPosition([params.lng, params.lat], params.zoom ? params.zoom : getZoom());
    if (!params.targetPos) {
      params.targetPos = centerVector;
    }
    controls.setLookAt(
      pos.x,
      pos.y,
      pos.z,
      params.targetPos.x,
      params.targetPos.y,
      params.targetPos.z,
      !params.suppressTransition
    );
  };

  const configureStats = (visible = false, mode = 0) => {
    stats.setMode(mode);

    if (visible) {
      document.body.appendChild(stats.domElement);
    } else if (stats.domElement.parentElement === document.body) {
      document.body.removeChild(stats.domElement);
    }
  };

  const animateScene = () => {
    // scale scene for 0 to 1
    new TWEEN.Tween({ k: 0 })
      .to({ k: 1 }, 3000)
      .easing(TWEEN.Easing.Quintic.Out)
      .on('update', ({ k }) => scene.scale.set(k, k, k))
      .start();

    // rotate scene
    const rotAxis = new THREE.Vector3(0, 1, 0);
    new TWEEN.Tween({ rot: Math.PI * 2 })
      .to({ rot: 0 }, 3000)
      .easing(TWEEN.Easing.Quintic.Out)
      .on('update', ({ rot }) => scene.setRotationFromAxisAngle(rotAxis, rot))
      .start();
  };

  const initEarth = async () => {
    codeWall = new CodeWall({ radius: GLOBE_RADIUS, segments: GLOBE_SEGMENTS });
    scene.add(codeWall.mesh);
    earth = new Earth({ radius: GLOBE_RADIUS, segments: GLOBE_SEGMENTS });
    scene.add(earth.container);
    const continents: any = await dataDb.getItem('continents');
    continentMarkers = new ContinentMarkers({
      continents,
      globeRadius: GLOBE_RADIUS,
      lineHeight: 60,
      renderer,
      camera,
      onClickMarker: props.onClickMarker,
    });
    scene.add(continentMarkers.container);
  };

  const initLights = () => {
    lights = new Lights(scene);
  };

  const initPostProcessing = () => {
    postProcessing = new PostProcessing({ camera, renderer, scene });
  };

  const initAnimationLoop = () => {
    renderer.setAnimationLoop(() => {
      earth.animationLoop(camera, clock);
      lights.animationLoop(camera);
      codeWall.animationLoop({ camera, clock, controls });
      if (candles) candles.animationLoop({ camera, clock, controls });
      if (arcs) arcs.animationLoop(clock);
      if (autoRotate) {
        controls.rotate(AUTO_ROTATION_COEFFICIENT, 0, false);
      }
    });
  };

  const renderScene = () => {
    if (postProcessing) {
      postProcessing.render();
    } else {
      renderer.render(scene, camera);
    }
  };

  const showNetworkNodes = (cities: CityProps[]) => {
    let wasOnStage = false;
    if (candles && candles.group) {
      scene.remove(candles.group);
      wasOnStage = true;
    }
    if (arcs && arcs.group) {
      scene.remove(arcs.group);
      arcs = null;
    }
    candles = new Candles({
      candleHeight: GLOBE_RADIUS,
      globeRadius: GLOBE_RADIUS,
      scene,
      cities,
      // cities: cities.slice(0,250),
    });
    scene.add(candles.group);

    if (!wasOnStage) {
      candles.appear();
    }

    setPointRays(pointRays);
  };

  const setPointRays = (value: boolean) => {
    pointRays = value;
    if (candles && candles.group) {
      if (pointRays) {
        scene.add(candles.group);
      } else {
        scene.remove(candles.group);
      }
    }
  };

  const showAttackArcs = (attackArcs: ArcAttack[]) => {
    if (candles && candles.group) {
      scene.remove(candles.group);
      candles = null;
    }
    if (arcs && arcs.group) {
      scene.remove(arcs.group);
    }
    arcs = new Arcs({
      attacks: attackArcs,
      globeRadius: GLOBE_RADIUS,
      showArcs: connectPointsWithArcs,
    });
    scene.add(arcs.group);
  };

  const setConnectPointsWithArcs = (value: boolean) => {
    connectPointsWithArcs = value;
    if (arcs && arcs.group) {
      arcs.setShowArcs(connectPointsWithArcs);
    }
  };

  const animate = () => {
    stats.begin();
    renderScene();
    continentMarkers?.updateMarkers();
    controls.update(clock.getDelta());
    TWEEN.update();
    stats.end();
    frameId = requestAnimationFrame(animate);
  };

  const start = () => {
    controls.enabled = true;
    if (!frameId) frameId = requestAnimationFrame(animate);
  };

  const stop = () => {
    controls.enabled = false;
    if (frameId) cancelAnimationFrame(frameId);
    frameId = null;
  };

  // Tracker: TMWQ-2688
  const enableControls = (status: boolean) => {
    controls.enabled = status;
  };

  const init = async (initState: GlobeState) => {
    props.init();
    // scene.scale.set(0, 0, 0); // keep it hidden until the data is fetched
    configureCamera(initState);
    await initEarth();
    initLights();
    initPostProcessing();
    configureStats();
    initAnimationLoop();
    start();
  };

  const destroy = () => {
    stop();
    props.destroy();
  };

  const zoomIn = () => {
    controls.dolly(60, true);
  };

  const zoomOut = () => {
    controls.dolly(-60, true);
  };

  const setZoom = (zoom: number, withTransition?: boolean) => {
    controls.dollyTo(zoom, withTransition);
  };

  const getZoom = (): number => {
    return camera.position.distanceTo(centerVector);
  };

  const resetZoom = (withTransition?: boolean) => {
    controls.dollyTo(GLOBE_CAMERA_POSITION_Z, withTransition);
  };

  const moveTo = ({ lng, lat, distance, withTransition }) => {
    const posToMove = coordinatesToPosition([lng, lat], getZoom());
    const posToZoom = coordinatesToPosition([lng, lat], distance);
    controls.setLookAt(posToMove.x, posToMove.y, posToMove.z, 0, 0, 0, withTransition);
    setTimeout(() => controls.setLookAt(posToZoom.x, posToZoom.y, posToZoom.z, 0, 0, 0, withTransition), 1000);
  };

  const rotate = (azimuthAmount, polarAmount, withTransition = true) => {
    controls.rotate(azimuthAmount * ROTATION_COEFFICIENT, polarAmount * ROTATION_COEFFICIENT, withTransition);
  };

  const setAutoRotate = (value: boolean) => {
    autoRotate = value;
  };

  const getAutoRotate = (): boolean => autoRotate;

  const getState = (): GlobeState => {
    const [lng, lat] = positionToCoordinates(camera.position);
    return {
      lng,
      lat,
      zoom: getZoom(),
      autoRotate,
    };
  };

  const spin = () => {
    const oldAutoRotate = autoRotate;
    autoRotate = true;
    const az = controls.azimuthAngle;
    const po = controls.polarAngle;

    new TWEEN.Tween({ az })
      .to({ az: az - Math.PI * 2 }, 3000)
      .easing(TWEEN.Easing.Quintic.Out)
      .on('update', ({ az }) => {
        controls.rotateTo(az, po, false);
      })
      .on('complete', () => (autoRotate = oldAutoRotate))
      .start();
  };

  const setContinentMarkerVisibility = (value: boolean) => {
    if (continentMarkers) continentMarkers.setVisibility(value);
  };

  const getContinentMarkersStatus = () => (continentMarkers ? true : false);

  return {
    controls,
    start,
    stop,
    enableControls, // Tracker: TMWQ-2688
    init,
    destroy,
    configureStats,
    showNetworkNodes,
    showAttackArcs,
    animateScene,
    setCameraPosFromLatLng,
    setConnectPointsWithArcs,
    setPointRays,
    zoomIn,
    zoomOut,
    setZoom,
    getZoom,
    resetZoom,
    moveTo,
    rotate,
    setAutoRotate,
    getAutoRotate,
    getState,
    spin,
    setContinentMarkerVisibility,
    getContinentMarkersStatus,
  };
});
