import { isMobileOnly } from 'react-device-detect';

import styled from 'styled-components';

export const StyledLogo = styled.div`
  position: absolute;
  top: ${isMobileOnly ? 25 : 48}px;
  left: ${isMobileOnly ? 25 : 64}px;
  z-index: 4;
`;
