import * as THREE from 'three';

import arcTextureImage from 'assets/textures/arc.jpg';
import circleTextureImage from 'assets/textures/circle.jpg';

import { ArcAttack } from '../../types';
import { getArcSplineFromCoords } from '../../utils';
import { ARCS_VISIBLE, ARC_DURATION, ATTACK_COLORS } from '../constants';
import fArcShader from '../shaders/fArcShader';
import fArcSpotShader from '../shaders/fArcSpotShader';
import vPassThroughShader from '../shaders/vPassThroughShader';

// Inspired by
// https://medium.com/@xiaoyangzhao/drawing-curves-on-webgl-globe-using-three-js-and-d3-draft-7e782ffd7ab

class Arcs {
  group: THREE.Group;
  arcTexture: THREE.Texture;
  circleTexture: THREE.Texture;

  arcs: any[];
  spots: any[];

  showArcs: boolean;

  constructor(p: { attacks: ArcAttack[]; globeRadius: number; showArcs: boolean }) {
    this.group = new THREE.Group();
    const loader = new THREE.TextureLoader();
    this.arcTexture = loader.load(arcTextureImage);
    this.circleTexture = loader.load(circleTextureImage);

    this.arcs = [];
    this.spots = [];

    this.showArcs = p.showArcs;

    const arcUniforms = {
      u_time: {
        value: 0,
      },
      u_distance: { type: 'f', value: 400 },
      u_index: 0,
    };

    const center = new THREE.Vector3(0, 0, 0);

    p.attacks.forEach((attack: ArcAttack, index: number) => {
      const { arcMesh, arcEnd } = this.createArc(attack, arcUniforms, p.globeRadius, p.attacks.length, index);
      const spotMesh = this.createSpot(attack, arcUniforms, arcEnd, p.attacks.length, index);
      spotMesh.lookAt(center);
      this.arcs.push(arcMesh);
      this.spots.push(spotMesh);
      // if (index < ARCS_VISIBLE) this.group.add(arcMesh);
      this.group.add(spotMesh);
    });
  }

  createArc(attack: ArcAttack, uniforms: any, globeRadius: number, attacksCount: number, index: number) {
    const { spline, steps } = getArcSplineFromCoords(attack.coordinates[0], attack.coordinates[1], globeRadius);

    const startTime = (index / ARCS_VISIBLE) * ARC_DURATION;
    const endTime = startTime + ARC_DURATION;
    const timeLoop = (attacksCount / ARCS_VISIBLE + 1) * ARC_DURATION;

    const material = new THREE.ShaderMaterial({
      uniforms: {
        ...uniforms,
        u_color: {
          value: new THREE.Color(ATTACK_COLORS[attack.attack_type]),
        },
        u_texture: {
          value: this.arcTexture,
        },
        u_index: {
          value: index,
        },
        u_startTime: {
          value: startTime,
        },
        u_endTime: {
          value: endTime,
        },
        u_timeLoop: {
          value: timeLoop,
        },
        u_loopedTime: {
          value: 0,
        },
      },
      vertexShader: vPassThroughShader,
      fragmentShader: fArcShader,
      transparent: true,
      side: THREE.DoubleSide,
      depthTest: true,
      depthWrite: false,
    });

    const geometry = new THREE.TubeBufferGeometry(spline, 32, 2, 2, false);
    const mesh = new THREE.Mesh(geometry, material);

    mesh.userData = {
      ...mesh.userData,
      index,
      startTime,
      endTime,
      timeLoop,
    };

    return { arcMesh: mesh, arcEnd: steps[3] };
  }

  createSpot(attack: ArcAttack, uniforms: any, pos: THREE.Vector3, attacksCount: number, index: number) {
    const geometry = new THREE.PlaneBufferGeometry(1.5, 1.5, 1, 1);
    geometry.applyMatrix4(new THREE.Matrix4().makeTranslation(-0.75, -0.75, 0));

    const startTime = (index / ARCS_VISIBLE) * ARC_DURATION;

    const material = new THREE.ShaderMaterial({
      uniforms: {
        ...uniforms,
        u_color: {
          value: new THREE.Color(ATTACK_COLORS[attack.attack_type]),
        },
        u_texture: {
          value: this.circleTexture,
        },
        u_index: {
          value: index,
        },
        u_startTime: {
          value: startTime,
        },
        u_endTime: {
          value: startTime + ARC_DURATION,
        },
        u_timeLoop: {
          value: (attacksCount / ARCS_VISIBLE) * ARC_DURATION,
        },
      },
      vertexShader: vPassThroughShader,
      fragmentShader: fArcSpotShader,
      transparent: true,
      side: THREE.DoubleSide,
      depthTest: true,
      depthWrite: false,
    });
    const mesh = new THREE.Mesh(geometry, material);
    mesh.position.copy(pos);
    return mesh;
  }

  animationLoop(clock: THREE.Clock) {
    this.group.children = [];
    this.arcs.forEach((arc, i) => {
      const time = clock.getElapsedTime();
      if (this.showArcs) {
        const loopedTime = time % arc.userData.timeLoop;
        if (loopedTime > arc.userData.startTime || loopedTime < arc.userData.endTime) {
          this.group.add(arc);
          arc.material.uniforms.u_time.value = time;
          arc.material.uniforms.u_loopedTime.value = loopedTime;
        }
      } else {
        this.group.add(this.spots[i]);
        this.spots[i].material.uniforms.u_time.value = time;
      }
    });
  }

  setShowArcs(value: boolean) {
    this.showArcs = value;
  }
}

export default Arcs;
