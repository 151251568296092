import React from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import { useDispatch } from 'react-redux';

import {
  setSearchView,
  toggleDemo,
  toggleFiltersPanel,
  toggleInfoPanel,
  toggleLayersPanel,
  toggleSearch,
  toggleSettingsPanel,
  toggleStatsVisibility,
} from 'store/structure/GlobalSettings/actions';
import { isWeb } from 'utils';

import { keyMap } from './constants';

export const Hotkeys = () => {
  const dispatch = useDispatch();

  const findLocation = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setSearchView({ view: 'continents', displaySelect: true }));
    dispatch(toggleSearch());
  };

  const preventEvents = (cb) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(cb());
  };

  const handleZoomIn = () => {
    document.dispatchEvent(new Event('ZoomIn'));
  };

  const handleZoomOut = () => {
    document.dispatchEvent(new Event('ZoomOut'));
  };

  const handlers = {
    TOGGLE_LAYERS_PANEL: preventEvents(toggleLayersPanel),
    TOGGLE_FILTERS_PANEL: preventEvents(toggleFiltersPanel),
    TOGGLE_SETTINGS_PANEL: preventEvents(toggleSettingsPanel),
    TOGGLE_INFO_PANEL: preventEvents(toggleInfoPanel),
    TOGGLE_DEMO: preventEvents(toggleDemo),
    ZOOM_IN: preventEvents(handleZoomIn),
    ZOOM_OUT: preventEvents(handleZoomOut),
    TOGGLE_SEARCH_PANEL: isWeb ? () => {} : preventEvents(toggleSearch),
    FIND_LOCATION: findLocation,
    TOGGLE_STATS: preventEvents(toggleStatsVisibility),
  };

  return <GlobalHotKeys keyMap={keyMap} handlers={handlers} />;
};
