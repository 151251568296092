import * as THREE from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';

import fBloomShader from '../shaders/fBloomShader';
import vPassThroughShader from '../shaders/vPassThroughShader';

class PostProcessing {
  camera: THREE.Camera;
  renderer: THREE.WebGLRenderer;
  scene: THREE.Scene;
  finalComposer: EffectComposer;
  bloomComposer: EffectComposer;

  constructor(parameters: { camera: THREE.Camera; renderer: THREE.WebGLRenderer; scene: THREE.Scene }) {
    this.camera = parameters.camera;
    this.scene = parameters.scene;
    this.renderer = parameters.renderer;

    const settings = {
      exposure: 1.7,
      bloomStrength: 0.4,
      bloomThreshold: 0.1,
      bloomRadius: 0.1,
    };

    this.renderer.toneMapping = THREE.CineonToneMapping;

    const renderScene = new RenderPass(this.scene, this.camera);

    const bloomPass = new UnrealBloomPass(
      new THREE.Vector2(window.innerWidth, window.innerHeight),
      settings.bloomStrength,
      settings.bloomRadius,
      settings.bloomThreshold
    );
    bloomPass.strength = settings.bloomStrength;
    bloomPass.radius = settings.bloomRadius;
    bloomPass.threshold = settings.bloomThreshold;

    this.bloomComposer = new EffectComposer(this.renderer);
    this.bloomComposer.renderToScreen = true;
    this.bloomComposer.addPass(renderScene);
    this.bloomComposer.addPass(bloomPass);

    const finalPass = new ShaderPass(
      new THREE.ShaderMaterial({
        uniforms: {
          baseTexture: { value: null },
          bloomTexture: { value: this.bloomComposer.renderTarget2.texture },
        },
        vertexShader: vPassThroughShader,
        fragmentShader: fBloomShader,
        defines: {},
      }),
      'baseTexture'
    );
    finalPass.needsSwap = true;

    this.finalComposer = new EffectComposer(this.renderer);
    this.finalComposer.addPass(renderScene);
    this.finalComposer.addPass(finalPass);
  }

  render() {
    this.bloomComposer.render();
    // this.finalComposer.render();
  }
}

export default PostProcessing;
