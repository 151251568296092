import styled, { keyframes } from 'styled-components';

import { StyledSpinnerWrapperProps } from './types';

const animate = keyframes`
  from {
    opacity: 1;
  }

  to {
     opacity: 0;
  }
`;

export const StyledSpinnerWrapper = styled.div<StyledSpinnerWrapperProps>`
  width: 33px;
  height: 33px;
  display: inline-block;
  overflow: hidden;
  background: none;
  transform: scale(${({ scale }) => scale});
`;

export const StyledSpinner = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.33);
  backface-visibility: hidden;
  transform-origin: 0 0;

  div {
    left: 45px;
    top: 3.5px;
    position: absolute;
    animation: ${animate} linear 1s infinite;
    background: ${({ color }) => color};
    width: 10px;
    height: 29px;
    border-radius: 5px / 5.51px;
    transform-origin: 5px 46.5px;
    box-sizing: content-box;

    &:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -0.8888888888888888s;
    }

    &:nth-child(2) {
      transform: rotate(40deg);
      animation-delay: -0.7777777777777778s;
    }

    &:nth-child(3) {
      transform: rotate(80deg);
      animation-delay: -0.6666666666666666s;
    }

    &:nth-child(4) {
      transform: rotate(120deg);
      animation-delay: -0.5555555555555556s;
    }

    &:nth-child(5) {
      transform: rotate(160deg);
      animation-delay: -0.4444444444444444s;
    }

    &:nth-child(6) {
      transform: rotate(200deg);
      animation-delay: -0.3333333333333333s;
    }

    &:nth-child(7) {
      transform: rotate(240deg);
      animation-delay: -0.2222222222222222s;
    }

    &:nth-child(8) {
      transform: rotate(280deg);
      animation-delay: -0.1111111111111111s;
    }

    &:nth-child(9) {
      transform: rotate(320deg);
      animation-delay: 0s;
    }
  }
`;
