export const isDesktop = process.env.APP === 'desktop';

export const isWeb = process.env.APP === 'web';

export const isNocc = process.env.APP === 'nocc';

export const isEnvProduction = process.env.NODE_ENV === 'production';

export const isEnvDevelopment = process.env.NODE_ENV === 'development';

export const isDebugProd = process.env.DEBUG_PROD === 'true';
