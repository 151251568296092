import { imagesApi } from 'api';
import capacityUtilizationLogo from 'assets/images/capacityUtilization.png';
import webApplicationAttacks from 'assets/images/webApplicationAttacks.png';
import arcTextureImage from 'assets/textures/arc.jpg';
import candleTextureImage from 'assets/textures/candle.jpg';
import circleTextureImage from 'assets/textures/circle.jpg';
import codeTexture from 'assets/textures/code-texture.jpg';
import globeImageBlurUrl from 'assets/textures/earth-land-4k-blur.jpg';
import globeImageUrl from 'assets/textures/earth-land-4k.jpg';

import { actionTypes, updateLoadingPercentage } from './actions';

// Action Creators
export const fetchImages = () => async (dispatch) => {
  const imagesToBePreloaded = [
    candleTextureImage,
    arcTextureImage,
    circleTextureImage,
    capacityUtilizationLogo,
    codeTexture,
    webApplicationAttacks,
    globeImageBlurUrl,
    globeImageUrl,
  ];

  await Promise.all(imagesToBePreloaded.map((image) => imagesApi.get(image)));

  dispatch({ type: actionTypes.success.images });
  dispatch(updateLoadingPercentage());
};
