import 'normalize.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-input-range/lib/css/index.css';
import 'focus-visible';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { ThemeProvider } from 'styled-components';

import { App } from './App';
import configureStore, { load } from './store';
import theme from './theme';

const store = configureStore();
load(store);

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);
