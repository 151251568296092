import { getOr } from 'lodash/fp';
import { createSelector } from 'reselect';

import { StateProps } from '../types';
import { initialState } from './reducer';

export const globalSettingsSelector = (state: StateProps) => state.globalSettings;

export const defaultViewVisibilitySelector = createSelector(
  globalSettingsSelector,
  getOr(initialState.showDefaultView, 'showDefaultView')
);

export const fpsVisibilitySelector = createSelector(
  globalSettingsSelector,
  (settings) => settings.userInterface.hud.checked && settings.userInterface.hud.options.fps.checked
);

export const logoSelector = createSelector(globalSettingsSelector, (settings) => settings.userInterface.logo);

export const pointRaysSelector = createSelector(
  globalSettingsSelector,
  (settings) => !!settings.capacityAndUtilization.pointRays.checked
);
export const connectPointsWithArcsSelector = createSelector(
  globalSettingsSelector,
  (settings) => !!settings.webApplicationAttacks.connectPointsWithArcs.checked
);

export const logoVisibilitySelector = createSelector(logoSelector, (logo) => logo.checked);

export const logoColorSelector = createSelector(logoSelector, (logo) => {
  const option = Object.values(logo.options).find((item) => item.checked);
  if (!option) return 'color';
  return option.name;
});

export const datasetSelector = createSelector(
  globalSettingsSelector,
  getOr(initialState.selectedDataset, 'selectedDataset')
);

export const currentViewSelector = createSelector(
  globalSettingsSelector,
  getOr(initialState.currentView, 'currentView')
);

export const datasetInfoPanelVisibility = createSelector(
  globalSettingsSelector,
  getOr(initialState.showDatasetInfoPanel, 'showDatasetInfoPanel')
);

export const overlayDatasetsSelector = createSelector(
  globalSettingsSelector,
  getOr(initialState.overlayDatasets, 'overlayDatasets')
);

export const layersPanelSelector = createSelector(
  globalSettingsSelector,
  getOr(initialState.showLayersPanel, 'showLayersPanel')
);

export const settingsPanelSelector = createSelector(
  globalSettingsSelector,
  getOr(initialState.showSettingsPanel, 'showSettingsPanel')
);

export const filtersPanelSelector = createSelector(
  globalSettingsSelector,
  getOr(initialState.showFiltersPanel, 'showFiltersPanel')
);

export const infoPanelSelector = createSelector(
  globalSettingsSelector,
  getOr(initialState.showInfoPanel, 'showInfoPanel')
);

export const demoSelector = createSelector(globalSettingsSelector, getOr(initialState.playDemo, 'playDemo'));

export const searchSelector = createSelector(globalSettingsSelector, getOr(initialState.searchPanel, 'searchPanel'));

export const searchVisibilitySelector = createSelector(searchSelector, getOr(false, 'visible'));

export const searchViewSelector = createSelector(searchSelector, getOr(initialState.searchPanel.info, 'info'));

export const continentMarkersSelector = createSelector(
  globalSettingsSelector,
  getOr(initialState.continentMarkersStatus, 'continentMarkersStatus')
);
