import { cnoise } from './common';

const vertexShader = `
  uniform vec3 u_viewVector;
  uniform float c;
  uniform float p;
  varying vec2 vUv;
  varying vec4 pos;
  void main() 
  {
    vec3 vNormal = normalize( normalMatrix * normal );
    vec3 vNormel = normalize( normalMatrix * u_viewVector );
    // intensity = pow( c - dot(vNormal, vNormel), p );
    vUv = uv;
    
    pos = projectionMatrix * modelViewMatrix * vec4( position.x, position.y, position.z, 1.0 );
    gl_Position = pos;
  }
`;

const fragmentShader = `
  #define PI 3.1415926

  uniform float u_time;
  uniform float u_x;
  uniform float u_y;
  uniform vec3 u_color;

  uniform sampler2D u_texture;
  uniform float u_distance;
  uniform bool u_blur;

  varying vec2 vUv;
  varying vec4 pos;

  ${cnoise}

  void main() 
  {
    vec2 uv = vUv;
    vec4 texel = texture2D( u_texture, uv );

    float perlin = cnoise(vec3((pos.x / pos.z) / 1.2, (pos.y / pos.z) / 1.2, (1000.0 + u_time) * .1)) + 1.0;


    float x = pos.x / pos.z;
    float y = pos.y / pos.z;

    texel.a = texel.b * 1.0;
    texel.a += max(0.0, (perlin - 0.5));
    texel.a *= texel.a * .5;
    texel.a *= .1;
    gl_FragColor = vec4(
      u_color.r + texel.r,
      u_color.g + texel.g,
      u_color.b + texel.b,
      texel.a
    );
  }
`;

export default {
  vertexShader,
  fragmentShader,
};
