import { toast } from 'react-toastify';

const getErrorMessage = (actionPayload): string => {
  if (typeof actionPayload === 'string') return actionPayload;

  if (!actionPayload.response) {
    return 'Network error ! Please try again later.';
  }

  // http status code
  const code = actionPayload.response.status;
  // response data
  const response = actionPayload.response.data;

  return `${code}: ${response}`;
};

export const errorMiddleware = () => (next) => async (action) => {
  const errorSuffix = '_ERROR';

  if (action.type.endsWith(errorSuffix)) {
    toast.error(getErrorMessage(action.payload), {
      position: 'bottom-right',
      autoClose: 3000,
      draggable: false,
    });
  }

  return next(action);
};
