const elem: any = document.documentElement;

/* View in fullscreen */
export const openFullscreen = () => {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) {
    /* Firefox */
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) {
    /* Chrome, Safari and Opera */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    /* IE/Edge */
    elem.msRequestFullscreen();
  }
};

/* Close fullscreen */
export const closeFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else {
    // @ts-ignore
    if (document.mozCancelFullScreen) {
      /* Firefox */ // @ts-ignore
      document.mozCancelFullScreen(); // @ts-ignore
    } else if (document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */ // @ts-ignore
      document.webkitExitFullscreen(); // @ts-ignore
    } else if (document.msExitFullscreen) {
      /* IE/Edge */ // @ts-ignore
      document.msExitFullscreen();
    }
  }
};

export const toggleFullscreen = () => {
  if (!window.screenTop && !window.screenY) {
    closeFullscreen();
    return;
  }

  openFullscreen();
};
