export default `
  #define PI 3.1415926

  uniform sampler2D u_texture;
  uniform float u_time;
  uniform float u_distance;
  uniform float u_index;
  uniform float u_startTime;
  uniform float u_endTime;
  uniform float u_timeLoop;
  uniform float u_loopedTime;
  uniform float u_duration;
  uniform bool u_blur;
  uniform vec3 u_color;

  varying vec2 vUv;

  void main() {
    float duration = (u_endTime - u_startTime);
    float timeNormal = (u_loopedTime - u_startTime) / duration;

    if (timeNormal < 0.0 || timeNormal > 1.0) {
      // Just return a transparent pixel if the time has not come or passed for
      // this object to be visible
      gl_FragColor = vec4(0, 0, 0, 0);
    } else {
      vec2 uv = vUv;

      uv.x -= -0.9 + (timeNormal * 2.0);

      vec4 texel = texture2D( u_texture, uv );
      texel.a = texel.b;
      texel.r = u_color.x;
      texel.g = u_color.y;
      texel.b = u_color.z;
  
      gl_FragColor = texel;
    }
  }
`;
