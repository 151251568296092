import { Color, DoubleSide, MeshPhongMaterial } from 'three';

export const GLOBE_RADIUS = 100;
export const GLOBE_SEGMENTS = 80;
export const GLOBE_CAMERA_POSITION_Z = 400;
export const GLOBE_BACKGROUND_COLOR = 0x010715;
export const GLOBE_AMBIENT_LIGHT_COLOR = 0xbbbbbb;
export const GLOBE_DIRECTIONAL_LIGHT_COLOR = 0xffffff;
export const GLOBE_DIRECTIONAL_LIGHT_INTENSITY = 0.6;
export const MARKER_UNIT_RADIUS_SCALE = 0.01;
export const CAMERA_DAMPING_FACTOR = 0.1;
export const ROTATION_COEFFICIENT = Math.PI * 0.1;
export const AUTO_ROTATION_COEFFICIENT = -Math.PI * 0.001;

export const ATTACK_COLORS = {
  'Remote File Inclusion': 0x5668ff,
  'PHP Injection': 0xe023ab,
  'SQL Injection': 0xff9933,
  'Cross-Site Scripting': 0xa041ff,
  'Command Injection': 0x5668ff,
};

export const ARCS_VISIBLE = 50;
export const ARC_DURATION = 2;

export const defaultOrbitControlOptions: any = {
  autoRotate: true,
  autoRotateSpeed: 0.2,
  dampingFactor: CAMERA_DAMPING_FACTOR,
  enableDamping: true,
  enablePan: false,
  enableRotate: true,
  enableZoom: true,
  maxDistance: 400,
  maxPolarAngle: (7 / 8) * Math.PI,
  minDistance: 125,
  minPolarAngle: Math.PI / 8,
  rotateSpeed: 0.2,
  zoomSpeed: 0.5,
};

export const countryHoveredMaterial = new MeshPhongMaterial({ color: new Color(0x1a9bfc), side: DoubleSide });
export const countryMaterial = new MeshPhongMaterial({ color: new Color(0x002b49), side: DoubleSide });

export const EXTRUSION_AMOUNT = 1.025;
