import styled from 'styled-components';

import { LogoFullColor } from 'assets/logos';

export const StyledLogo = styled(LogoFullColor)`
  position: absolute;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);

  @media screen and (min-device-width: 1024px) {
    top: 48px;
    left: 64px;
    transform: none;
  }
`;

export const VersionInfo = styled.div`
  position: absolute;
  left: 20px;
  bottom: 0px;
  padding: 3px;
  background-color: rgba(0, 0, 0, 0.3);
  font-size: 13px;
  color: #333333;
  z-index: 9;
`;

export const StyledModal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 900;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

export const StyledModalContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 10px 40px;

  @media screen and (min-device-width: 1024px) {
    padding: 0 64px 55px;
  }
`;

export const StyledModalItem = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;
  align-items: center;

  @media screen and (min-device-width: 1024px) {
    align-items: flex-start;
  }

  & > span:first-child {
    font-family: 'Ubuntu', monospace;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0.03em;
    color: #ff9933;
    text-transform: uppercase;

    @media screen and (min-device-width: 1024px) {
      font-size: 22px;
    }
  }

  & > span:nth-child(2) {
    font-family: 'Avenir Next', monospace;
    font-style: normal;
    font-weight: 600;
    font-size: 10vw;
    line-height: 100%;
    letter-spacing: -0.03em;
    color: #ffffff;

    @media screen and (min-device-width: 1024px) {
      font-size: 112px;
    }
  }

  & > span:nth-child(3) {
    font-family: 'Ubuntu', monospace;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.01em;
    color: #ffffff;

    @media screen and (min-device-width: 1024px) {
      font-size: 24px;
    }
  }
`;

export const StyledModalButton = styled.div`
  width: 112px;
  height: 112px;
  background: rgba(0, 7, 20, 0.6);
  border-radius: 60px;
  padding: 20px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  user-select: none;

  font-family: 'Ubuntu', monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 112.5%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);

  &:after {
    content: '';
    position: absolute;
    width: 96px;
    height: 96px;
    border-radius: 48px;
    left: 6.5px;
    top: 6.5px;
    border: 1.5px solid #ff9933;
    filter: drop-shadow(0px 0px 36px #0085ff);
  }
`;
