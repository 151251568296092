import * as THREE from 'three';

import codeTexture from 'assets/textures/code-texture.jpg';

import codeWallShader from '../shaders/codeWallShader';

class CodeWall {
  mesh: THREE.Mesh<THREE.CylinderBufferGeometry, THREE.ShaderMaterial>;

  constructor(parameters: { radius: number; segments: number }) {
    const loader = new THREE.TextureLoader();

    const codeWallGeometry = new THREE.CylinderBufferGeometry(
      parameters.radius * 4,
      parameters.radius * 4,
      parameters.radius * 20,
      parameters.segments,
      parameters.segments
    );
    codeWallGeometry.scale(-1, 1, 1);
    const codeWallTexture = loader.load(codeTexture);

    const uniforms = {
      u_time: {
        value: 0,
      },
      u_distance: { type: 'f', value: 400 },
    };

    // create the material for the front object
    const codeWallMaterial = new THREE.ShaderMaterial({
      uniforms: {
        ...uniforms,
        u_texture: { value: codeWallTexture },
        c: { value: 1.0 },
        p: { value: 2.4 },
        u_color: { value: new THREE.Color(0x020d1d) },
        u_viewVector: { value: new THREE.Vector3() },
        u_x: { value: 0 },
        u_y: { value: 0 },
      },
      vertexShader: codeWallShader.vertexShader,
      fragmentShader: codeWallShader.fragmentShader,
      transparent: true,
      side: THREE.FrontSide,
    });

    this.mesh = new THREE.Mesh(codeWallGeometry, codeWallMaterial);
  }

  animationLoop(p: { camera: THREE.Camera; clock: THREE.Clock; controls?: any }) {
    this.mesh.material.uniforms.u_time.value = p.clock.getElapsedTime();
  }
}

export default CodeWall;
