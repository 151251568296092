import React, { Suspense, useEffect } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import { selectDataset } from 'store/structure/GlobalSettings/actions';
import { toggleDefaultView } from 'store/structure/GlobalSettings/actions';

import { Logo } from './components/atoms/Logo';
import { Spinner } from './components/atoms/Spinner';
import { DatasetSelectorLayer } from './components/molecules/DatasetSelectorLayer';
import { FooterLinks } from './components/molecules/FooterLinks';
import { Hotkeys } from './components/molecules/Hotkeys';
import { BottomButtons, MiddleButtons, TopButtons } from './components/molecules/SideButtons';
import { DatasetInfoPanel } from './components/organisms/DatasetInfoPanel';
import { DataWatcherAndLoader } from './components/organisms/DataWatcherAndLoader';
import { FiltersPanel } from './components/organisms/FiltersPanel';
import { InfoPanel } from './components/organisms/InfoPanel';
import { LayersPanel } from './components/organisms/LayersPanel';
import { Search } from './components/organisms/Search';
import { SettingsPanel } from './components/organisms/SettingsPanel';
import { chartVisibilitySelector } from './store/structure/Chart/selectors';
import { dataLoadingSelector, initialDataLoadedSelector } from './store/structure/Data/selectors';
import { defaultViewVisibilitySelector } from './store/structure/GlobalSettings/selectors';
import { isWeb } from './utils';
import { DefaultView } from './views/DefaultView';
import { VersionInfo } from './views/DefaultView/DefaultView.styled';
import { GlobeMapView } from './views/GlobeMapView';
import { PolarChart } from './views/PolarChart';

import './App.scss';

export const App = () => {
  const loading = useSelector(dataLoadingSelector);
  const initialDataLoaded = useSelector(initialDataLoadedSelector);
  const defaultViewVisible = useSelector(defaultViewVisibilitySelector);
  const chartVisible = useSelector(chartVisibilitySelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (initialDataLoaded) {
      const preloader: HTMLDivElement | null = document.querySelector('.preloader__wrapper');
      if (preloader) preloader.style.display = 'none';
      const searchParams = new URLSearchParams(document.location.search);
      const view = searchParams.get('view');

      window.localStorage.setItem('isDBWorkerBusy', 'false');

      if (view === 'attacks') {
        dispatch(selectDataset('webAppAttacks'));
        dispatch(toggleDefaultView());
      }
    }
  }, [initialDataLoaded]);

  return (
    <>
      <DataWatcherAndLoader />
      <ToastContainer />

      <LoadingOverlay active={loading} spinner={<Spinner color="#009CDE" />} className="loading-overlay">
        {initialDataLoaded && <GlobeMapView />}
      </LoadingOverlay>

      <Suspense fallback={<div />}>
        <DatasetInfoPanel />
        <DefaultView />
      </Suspense>

      {!defaultViewVisible && (
        <Suspense fallback={<div />}>
          <Logo />
          <TopButtons />
          <MiddleButtons />
          <BottomButtons />
          <DatasetSelectorLayer />
          <LayersPanel />
          <FiltersPanel />
          <SettingsPanel />
          <InfoPanel />
          {!isWeb && <Search />}
          <Hotkeys />
        </Suspense>
      )}
      {chartVisible && !isWeb && (
        <Suspense fallback={<div />}>
          <PolarChart />
        </Suspense>
      )}

      <Suspense fallback={<div />}>
        <FooterLinks />
      </Suspense>

      <VersionInfo>{process.env.VERSION}</VersionInfo>
    </>
  );
};
