import { baseURL, privateBaseURL, publicBaseURL } from './constants';

export const endpoints = {
  attacks: {
    global: `${baseURL}/attacks/globals`,
    arcs: `${baseURL}/attacks/feed`,
  },
  traffic: {
    globals: `${publicBaseURL}/traffic/globals`,
    cities: `${publicBaseURL}/traffic/cities`,
    city: `${publicBaseURL}/traffic/city`,
  },
  download: {
    continents: `${baseURL}/static/continents.tsv`,
    countries: `${baseURL}/static/countries.tsv`,
    cities: `${privateBaseURL}/static/city.tsv`,
    regions: `${privateBaseURL}/static/region.tsv`,
    machines: `${privateBaseURL}/static/machines.tsv`,
  },
  mocks: {
    attacks: {
      global: '/3375598bc3352395b2067ddb6f84d793/raw/4ccb42538f2736da4a7b06ce48fdea618e607d39/attacks-globals.json',
      arcs: '/da9e07e40bdc9fe187bf37862a583534/raw/5d95217bf26b5a81adde33676310b3efc84b5198/attacks-feed.json',
    },
    traffic: {
      globals: '/1a8b2015013639c6b13d6c7523d3828c/raw/85a821cc856a1f8b86b8d0032df8d78a156be828/traffic-globals.json',
      cities: '/986da466a0d960db6baeed1cf4657839/raw/a904caf2753c30c55f25e5a0cb350d3e279a886e/traffic-cities.tsv',
      city: '/2f51acd790ce4fdb6585c187eb8c0b8a/raw/9e5aac2c62a53430dcbadd14b05288a31499ae41/traffic-city.json',
    },
    download: {
      continents:
        'dbac9e07e0705d4f5db91b694c33118f/raw/e9d15de9bc150c017c221338d5d7b51b84ecb24e/download-continents.tsv',
      countries:
        '/0a13d9c085324b5ca804536eff9d0310/raw/e4300f9e553b6d0217ff4e9e11346b4f2f4af3bb/download-countries.tsv',
      cities: '/c837560f29770896c378bf1185a3fd70/raw/c065b44ca6841a07970a6c8c4bda79f6b4acedc9/download-cities.tsv',
      regions: '/264840456a6ed779570f8f8bc90442f3/raw/b5de1758729e9ec7d00404015181e7ce497d1aa3/download-regions.tsv',
      machines: '/eeb4de56cb897abf1a6e23615b87b3ad/raw/c9c2dcb2f7ddf9b94e4f730e8f6f27b2ecf133ee/download-machines.tsv',
    },
  },
};
