import { cnoise } from './common';

export const vertexShader = `
  varying vec2 vUv;

  void main()
  {
    vUv = uv;
    vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
    gl_Position = projectionMatrix * mvPosition;
  }
`;

export const fragmentShader = `
  #define PI 3.1415926

  uniform sampler2D u_texture;
  uniform float u_time;
  uniform float u_index;
  uniform bool u_blur;
  uniform vec3 u_color;

  varying vec2 vUv;

  ${cnoise}

  float dotMatrix(float x, float y, float distance) {
    float varyX = sin(x * 2000.0 / distance);
    float varyY = cos(y * 1000.0 / distance);
    float dotMatrix = varyX * varyX * varyY * varyY;
    return dotMatrix;
  }

  void main() {
    vec2 uv = vUv;
    vec4 texel = texture2D( u_texture, uv );

    texel.a = texel.b;
    texel.r = u_color.x;
    texel.g = u_color.y;
    texel.b = u_color.z;

    float perlin = cnoise(vec3(
      (uv.x + u_index * 0.0) *
      (1.0 + u_index * 1.0),
      (uv.y + u_index * 0.0) *
      (8.0 + u_index * 1.0),
      u_time * (2.0 + u_index)
    ));

    float tipRatio = (1.0 - uv.y) * .25;

    gl_FragColor = vec4(texel.rgb - (perlin * tipRatio), texel.a);
  }
`;

export default {
  vertexShader,
  fragmentShader,
};
