import { useEffect } from 'react';
/**
 * This component doesn't return any element but it loads all the necessary data without effecting the rendering of the
 * entire application
 * @constructor
 */
import { useDispatch } from 'react-redux';

import { downloadData, fetchGlobalTraffic } from 'store/structure/Data/actions';
import { fetchArcsAttacks, fetchGlobalAttacks } from 'store/structure/Data/actions';
import { fetchImages } from 'store/structure/Data/images.actions';

import { useAppRefresh } from './useAppRefresh';

export const DataWatcherAndLoader = () => {
  const dispatch = useDispatch();
  useAppRefresh();

  useEffect(() => {
    // load the data necessary for rendering the attacks
    dispatch(fetchArcsAttacks());
    dispatch(fetchGlobalAttacks());

    // Load the Continents, Countries, Cities, Regions & Machines
    dispatch(downloadData());

    // load all the images before displaying the globe
    dispatch(fetchImages());

    // fetch global traffic necessary for the default view
    dispatch(fetchGlobalTraffic());
  }, []);

  return null;
};
