import { getOr } from 'lodash/fp';
import { createSelector } from 'reselect';

import { initialState } from './reducer';

export const dataSelector = (state) => state.data;

export const dataLoadingSelector = createSelector(dataSelector, getOr(false, 'loading'));
export const loadingPercentageSelector = createSelector(dataSelector, getOr(0, 'percentage'));
export const dataErrorSelector = createSelector(dataSelector, getOr(false, 'error'));
export const dataLoadedSelector = createSelector(dataSelector, getOr(initialState.loaded, 'loaded'));

export const arcsAttacksLoadedSelector = createSelector(dataLoadedSelector, getOr(false, 'arcsAttacks'));
export const globalAttacksLoadedSelector = createSelector(dataLoadedSelector, getOr(false, 'globalAttacks'));
export const attacksLoadedSelector = createSelector(
  arcsAttacksLoadedSelector,
  globalAttacksLoadedSelector,
  (a, b) => a && b
);

export const imagesLoadedSelector = createSelector(dataLoadedSelector, getOr(false, 'images'));
export const basicDataLoadedSelector = createSelector(dataLoadedSelector, getOr(false, 'basic'));
export const globalTrafficLoadedSelector = createSelector(dataLoadedSelector, getOr(false, 'globalTraffic'));

export const initialDataLoadedSelector = createSelector(
  attacksLoadedSelector,
  basicDataLoadedSelector,
  imagesLoadedSelector,
  globalTrafficLoadedSelector,
  (a, b, c, d) => {
    return a && b && c && d;
  }
);
