export const actionTypes = {
  toggleStats: 'SETTINGS_PANEL_TOGGLE_STATS',
  selectDataset: 'SETTINGS_PANEL_SELECT_DATASET',
  toggleLayersPanel: 'SETTINGS_TOGGLE_LAYERS_PANEL',
  toggleSettingsPanel: 'SETTINGS_TOGGLE_SETTINGS_PANEL',
  toggleFiltersPanel: 'SETTINGS_TOGGLE_FILTERS_PANEL',
  toggleInfoPanel: 'SETTINGS_TOGGLE_INFO_PANEL',
  toggleCheckbox: 'SETTINGS_TOGGLE_CHECKBOX',
  toggleRadio: 'SETTINGS_TOGGLE_RADIO',
  toggleSearch: 'SETTINGS_TOGGLE_SEARCH',
  toggleDemo: 'SETTINGS_TOGGLE_DEMO',
  toggleDefaultView: 'SETTINGS_TOGGLE_DEFAULT_VIEW',
  toggleDatasetInfoPanel: 'SETTINGS_TOGGLE_DATASET_INFO_PANEL',
  setRangeInput: 'SETTINGS_SET_RANGE_INPUT',
  setSearchView: 'SETTINGS_SET_SEARCH_VIEW',
  setContinentMarkers: 'SETTING_CONTINENT_MARKERS',
};

export const toggleStatsVisibility = (state?) => ({ type: actionTypes.toggleStats, payload: state });

export const selectDataset = (dataset) => ({ type: actionTypes.selectDataset, payload: dataset });
export const toggleDatasetInfoPanel = (state?) => ({ type: actionTypes.toggleDatasetInfoPanel, payload: state });

export const toggleLayersPanel = (state?) => ({ type: actionTypes.toggleLayersPanel, payload: state });
export const toggleSettingsPanel = (state?) => ({ type: actionTypes.toggleSettingsPanel, payload: state });
export const toggleFiltersPanel = (state?) => ({ type: actionTypes.toggleFiltersPanel, payload: state });
export const toggleInfoPanel = (state?) => ({ type: actionTypes.toggleInfoPanel, payload: state });

export const toggleDefaultView = (state?) => ({ type: actionTypes.toggleDefaultView, payload: state });

export const toggleSearch = (state?) => ({ type: actionTypes.toggleSearch, payload: state });

export const setSearchView = (view?) => ({ type: actionTypes.setSearchView, payload: view });

export const toggleDemo = (state?) => ({ type: actionTypes.toggleDemo, payload: state });

export const toggleCheckbox = (path) => ({ type: actionTypes.toggleCheckbox, payload: path });

export const toggleRadio = (path) => ({ type: actionTypes.toggleRadio, payload: path });

export const setRangeInput = (path, value) => ({ type: actionTypes.setRangeInput, payload: { path, value } });

export const setContinentMarkers = (state) => ({ type: actionTypes.setContinentMarkers, payload: state });
