import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { debounce, throttle } from 'lodash';
import clamp from 'lodash/clamp';

import { ATTACK_SCREEN, TRAFFIC_SCREEN, dataDb } from 'api';
import { loadChart } from 'store/structure/Chart/actions';
import { attacksLoadedSelector } from 'store/structure/Data/selectors';
import { setContinentMarkers } from 'store/structure/GlobalSettings/actions';
import {
  connectPointsWithArcsSelector,
  datasetInfoPanelVisibility,
  datasetSelector,
  defaultViewVisibilitySelector,
  fpsVisibilitySelector,
  pointRaysSelector,
  searchVisibilitySelector,
} from 'store/structure/GlobalSettings/selectors';
import { setAutoRotate, setCurrentView, setGlobeMapState, toggleAutoRotate } from 'store/structure/GlobeMap/actions';
import {
  autoRotateSelector,
  currentViewSelector,
  forceViewSelector,
  globeStateSelector,
  mapStateSelector,
} from 'store/structure/GlobeMap/selectors';
import { isWeb } from 'utils';

import {
  GLOBE_SWITCH_FALLOFF,
  GLOBE_SWITCH_POINT,
  MAP_SWITCH_FALLOFF,
  MAP_SWITCH_POINT,
  WEB_CANDLES_LIMIT,
  ZOOM_LEVELS,
} from './constants';
import { Globe } from './Globe/Globe.three';
import { MapBox } from './MapBox';
import { GlobeOutProps } from './types';

export const useGlobeMapViewLogic = () => {
  const dispatch = useDispatch();
  const pointRays = useSelector(pointRaysSelector);
  const connectPointsWithArcs = useSelector(connectPointsWithArcsSelector);

  const mapStateStore = useSelector(mapStateSelector);
  const globeStateStore = useSelector(globeStateSelector);
  const forceViewStore = useSelector(forceViewSelector);
  const currentViewStore = useSelector(currentViewSelector);
  const autoRotateStore = useSelector(autoRotateSelector);
  const selectedDatasetStore = useSelector(datasetSelector);

  const isDatasetInfoPanelVisible = useSelector(datasetInfoPanelVisibility);
  const defaultViewVisible = useSelector(defaultViewVisibilitySelector);
  const attacksLoaded = useSelector(attacksLoadedSelector);
  const statsVisible = useSelector(fpsVisibilitySelector);
  const searchVisible = useSelector(searchVisibilitySelector);

  const globeMount = useRef<HTMLDivElement>(null);
  const globeRef = useRef<GlobeOutProps | null>(null);

  const mapBoxMount = useRef<HTMLDivElement>(null);
  const mapBoxRef = useRef<any | null>(null);

  const currentView = useRef('globe');
  const forceView = useRef<string | null>(null);
  const disableSync = useRef(false);
  const interactionEnabled = useRef(false);
  const selectedDataSet = useRef(selectedDatasetStore);

  const handleClickMarker = (data) => {
    // Tracker: TMWQ-2688
    if (!globeRef.current || selectedDataSet.current === ATTACK_SCREEN) return;

    if (data.type === 'continents') {
      data.exploreMode = 'map';
      navigateToPosition({ detail: data });
      return;
    }

    if (isWeb) return;

    dispatch(loadChart(data));
  };

  const setVisibilities = (globeOpacity: number) => {
    if (globeMount.current && mapBoxMount.current) {
      if (forceView.current === 'map') {
        globeOpacity = 0;
      } else if (forceView.current === 'globe') {
        globeOpacity = 1;
      }

      globeMount.current.style.opacity = globeOpacity.toString();
      mapBoxMount.current.style.opacity = (1 - globeOpacity).toString();
      if (globeOpacity > 0) {
        globeMount.current.style.display = 'block';
        mapBoxMount.current.style.display = 'none';
        if (currentView.current === 'map' && globeRef.current) {
          forceView.current = null;
          globeRef.current.start();
          window.dispatchEvent(new Event('resize'));
        }
        currentView.current = 'globe';
        mapBoxRef.current.setActive(false);
        updateGlobePosition.flush();
      } else {
        // Tracker: TMWQ-2688
        if (selectedDataSet.current === ATTACK_SCREEN) {
          mapBoxMount.current.style.display = 'none';
          globeMount.current.style.display = 'block';
          globeMount.current.style.opacity = '1';

          if (currentView.current === 'globe' && globeRef.current) {
            globeRef.current?.setZoom(GLOBE_SWITCH_POINT + GLOBE_SWITCH_FALLOFF * 2);
            globeRef.current?.stop();
          }
          return;
        }

        mapBoxMount.current.style.display = 'block';
        globeMount.current.style.display = 'none';

        if (currentView.current === 'globe' && globeRef.current) {
          globeRef.current.setZoom(GLOBE_SWITCH_POINT + GLOBE_SWITCH_FALLOFF * 2);
          globeRef.current.stop();
          currentView.current = 'map';
          forceView.current = 'map';
          window.dispatchEvent(new Event('resize'));
        }

        currentView.current = 'map';
        mapBoxRef.current.setActive(true);
        mapBoxRef.current.updateMarkers();
        updateMapBox.flush();

        // mapBoxMount.current.style.display = 'block';
        // globeMount.current.style.display = 'none';
        // if (selectedDataSet.current == 'webAppAttacks') {
        //   if (currentView.current === 'globe' && globeRef.current) {
        //     globeRef.current.setZoom(GLOBE_SWITCH_POINT + GLOBE_SWITCH_FALLOFF * 1.5);
        //     globeRef.current.stop();
        //     console.log('dont change view');
        //     currentView.current = 'globe';
        //     forceView.current = 'globe';
        //   }
        // } else {
        //   if (currentView.current === 'globe' && globeRef.current) {
        //     globeRef.current.setZoom(GLOBE_SWITCH_POINT + GLOBE_SWITCH_FALLOFF * 2);
        //     globeRef.current.stop();
        //     currentView.current = 'map';
        //     forceView.current = 'map';
        //     window.dispatchEvent(new Event('resize'));
        //   }
        //   currentView.current = 'map';
        //   mapBoxRef.current.setActive(true);
        // }
        // updateMapBox.flush();
      }
    }
  };

  const handleZoomIn = () => {
    // const zoomLevel = globeRef.current?.getZoom();
    // if (zoomLevel && zoomLevel < ATTACK_MAX_ZOOM_LEVEL) return;

    globeRef.current?.zoomIn();
    mapBoxRef.current?.zoomIn();
    // if (
    //   selectedDataSet.current == ATTACK_SCREEN &&
    //   typeof zoomLevel != 'undefined' &&
    //   zoomLevel > ATTACK_MAX_ZOOM_LEVEL
    // ) {
    //   globeRef.current?.zoomIn();
    //   mapBoxRef.current?.zoomIn();
    // } else if (selectedDataSet.current == TRAFFIC_SCREEN) {
    //   globeRef.current?.zoomIn();
    //   mapBoxRef.current?.zoomIn();
    // }
  };

  const handleZoomOut = () => {
    globeRef.current?.zoomOut();
    mapBoxRef.current?.zoomOut();
  };

  const keyToRotationMap = {
    ArrowUp: [0, -1],
    ArrowDown: [0, 1],
    ArrowLeft: [1, 0],
    ArrowRight: [-1, 0],
  };
  const handleKeyDown = (e: KeyboardEvent) => {
    if (!interactionEnabled.current) {
      return;
    }

    if (currentView.current === 'globe') {
      switch (e.key) {
        case 'ArrowUp':
        case 'ArrowDown':
        case 'ArrowLeft':
        case 'ArrowRight':
          {
            const [az, po] = keyToRotationMap[e.key];
            globeRef.current?.rotate(az, po);
          }
          break;
        case 'R':
        case 'r':
          globeRef.current?.spin();
          break;
        case ' ':
          dispatch(toggleAutoRotate());
          break;
        case '+':
          if (e.shiftKey) {
            // Tracker: TMWQ-2688
            selectedDataSet.current !== ATTACK_SCREEN && globeRef.current?.zoomIn();
          }
          break;
        case '_': // Shift + "-"
          if (e.shiftKey) {
            globeRef.current?.zoomOut();
          }
          break;
      }
    } else {
      switch (e.key) {
        case 'Escape':
          dispatch(setCurrentView('globe'));
          break;
      }
    }
  };

  const handleInteractionStart = () => {
    if (interactionEnabled.current && globeRef.current?.getAutoRotate()) {
      if (selectedDataSet.current === TRAFFIC_SCREEN) {
        dispatch(setAutoRotate(false));
      }
    }
  };

  const navigateToPosition = (e) => {
    const { lng, lat, exploreMode } = e.detail;

    disableSync.current = true;
    globeRef.current?.moveTo({ lng, lat, distance: ZOOM_LEVELS[exploreMode].globe, withTransition: true });
    mapBoxRef.current?.moveTo({ lng, lat, zoom: ZOOM_LEVELS[exploreMode].map });
    setTimeout(() => {
      disableSync.current = false;
    }, 2500);
  };

  const handleGlobeCameraUpdate = ({ distance, lng, lat }) => {
    if (currentView.current !== 'globe') return;
    const globeOpacity = clamp((distance - GLOBE_SWITCH_POINT) / GLOBE_SWITCH_FALLOFF, 0, 1);
    const mapBoxZoom = MAP_SWITCH_POINT - globeOpacity * (2 * MAP_SWITCH_FALLOFF);
    updateMapBox({ zoom: mapBoxZoom, lng: lng - 90, lat });
    setVisibilities(globeOpacity);
    dispatchGlobeMapState();
  };

  const updateMapBox = throttle(({ zoom, lng, lat }) => {
    if (disableSync.current) return;
    mapBoxRef.current?.setZoomAndCoords({ zoom, lng, lat });
  }, 1000);

  const handleMapBoxZoomUpdate = (zoom: number) => {
    if (currentView.current !== 'map') return;

    // If the user is trying to zoom out from the minimum allowed level they can
    // switch to the globe view.
    if (zoom < MAP_SWITCH_POINT) {
      forceView.current = null;
    }

    const mapOpacity = clamp((zoom - MAP_SWITCH_POINT + MAP_SWITCH_FALLOFF) / MAP_SWITCH_FALLOFF, 0, 1);

    setVisibilities(1 - mapOpacity);
    dispatchGlobeMapState();
  };

  const handleMapBoxPositionUpdate = ({ lat, lng }) => {
    if (currentView.current !== 'map') return;
    if (globeRef.current) {
      updateGlobePosition({ lat, lng });
    }
    dispatchGlobeMapState();
  };

  const updateGlobePosition = throttle(({ lat, lng }) => {
    if (disableSync.current) return;
    globeRef.current?.setCameraPosFromLatLng({ lat, lng });
  }, 1000);

  // Due to design changes we no longer will need to restore the globe and map
  // states, though we'll still updated them in case we later need that
  // functionality. If it's decided that we'll never need it, we should use only
  // setCurrentView instead to update the current view icon.
  const dispatchGlobeMapState = debounce(() => {
    if (globeRef.current && mapBoxRef.current) {
      dispatch(
        setGlobeMapState({
          globe: globeRef.current.getState(),
          map: mapBoxRef.current.getState(),
          forceView: forceView.current,
          currentView: currentView.current,
        })
      );
    }
  }, 250);

  useEffect(() => {
    if (!attacksLoaded) return;
    if (selectedDatasetStore === ATTACK_SCREEN) {
      // Tracker: TMWQ-2688
      if (globeRef && globeRef.current) {
        globeRef.current.enableControls(false);
        globeRef.current.setAutoRotate(true);
      }

      dataDb.getItem('attacks.arcs').then((data) => {
        if (data && globeRef && globeRef.current) globeRef.current.showAttackArcs(data);
      });
    } else if (selectedDatasetStore === TRAFFIC_SCREEN) {
      // Tracker: TMWQ-2688
      if (globeRef && globeRef.current) {
        globeRef.current.start();
      }
    }
  }, [attacksLoaded, selectedDatasetStore]);

  useEffect(() => {
    selectedDataSet.current = selectedDatasetStore;
    if (selectedDatasetStore === TRAFFIC_SCREEN) {
      dataDb.getItem('cities').then((data: any) => {
        if (data) {
          if (globeRef && globeRef.current) {
            let values = Object.values(data);
            if (isWeb) {
              values = values.slice(0, WEB_CANDLES_LIMIT);
            }
            globeRef.current.showNetworkNodes(values);
          }
          if (mapBoxRef && mapBoxRef.current) mapBoxRef.current.showNetworkNodes(Object.values(data));
        }
      });
    }
  }, [selectedDatasetStore]);

  useEffect(() => {
    // display stats depending on the Settings Panel
    if (!globeRef.current) return;
    globeRef.current.configureStats(statsVisible);
  }, [statsVisible, globeRef.current]);

  useEffect(() => {
    // the renderer is listening to the window.resize event
    // so when te infoPanel hides, force the renderer to update
    window.dispatchEvent(new Event('resize'));
  }, [isDatasetInfoPanelVisible, defaultViewVisible]);

  useEffect(() => {
    interactionEnabled.current = !defaultViewVisible && !searchVisible;
    globeRef.current?.setContinentMarkerVisibility(
      !defaultViewVisible && selectedDatasetStore !== ATTACK_SCREEN // Tracker: TMWQ-2688
    );
    if (defaultViewVisible) {
      dispatch(setCurrentView('globe'));
      dispatch(setAutoRotate(true));
    }
  }, [searchVisible, defaultViewVisible, selectedDatasetStore]);

  useEffect(() => {
    if (currentView.current === currentViewStore) return;
    if (currentViewStore === 'globe') {
      forceView.current = null;
      globeRef.current?.resetZoom();
      setVisibilities(1);
    } else {
      forceView.current = 'map';
      setVisibilities(0);
    }
  }, [currentViewStore]);

  useEffect(() => {
    globeRef.current?.setPointRays(pointRays);
  }, [pointRays, globeRef.current]);

  useEffect(() => {
    globeRef.current?.setConnectPointsWithArcs(connectPointsWithArcs);
  }, [connectPointsWithArcs, globeRef.current]);

  useEffect(() => {
    globeRef.current?.setAutoRotate(autoRotateStore);
  }, [autoRotateStore]);

  useEffect(() => {
    currentView.current = currentViewStore;
    forceView.current = forceViewStore;

    document.addEventListener('NavigateToPosition', navigateToPosition);
    document.addEventListener('ZoomIn', handleZoomIn);
    document.addEventListener('ZoomOut', handleZoomOut);
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('mousedown', handleInteractionStart);
    document.addEventListener('touchstart', handleInteractionStart);

    // On mount, load the options from Redux state & initialize the globe &
    // MapBox
    const globe = (globeRef.current = Globe({
      el: globeMount.current as HTMLDivElement,
      updateCameraCb: handleGlobeCameraUpdate,
      onClickMarker: handleClickMarker,
    }));

    const mapBox = (mapBoxRef.current = MapBox({
      el: mapBoxMount.current as HTMLDivElement,
      zoom: 1,
      updateZoomCb: handleMapBoxZoomUpdate,
      updatePositionCb: handleMapBoxPositionUpdate,
      onClickMarker: handleClickMarker,
    }));

    (async () => {
      await globe.init(globeStateStore);
      await mapBox.init(mapStateStore);
      dispatch(setContinentMarkers(globe.getContinentMarkersStatus()));
    })();

    return () => {
      document.addEventListener('NavigateToPosition', navigateToPosition);
      document.removeEventListener('ZoomIn', handleZoomIn);
      document.removeEventListener('ZoomOut', handleZoomOut);
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('mouseDown', handleInteractionStart);
      document.removeEventListener('touchstart', handleInteractionStart);

      globe.destroy();
    };
  }, []);

  return {
    narrow: isDatasetInfoPanelVisible && !defaultViewVisible,
    mapBoxMount,
    globeMount,
  };
};
