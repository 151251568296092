import { autoRotateSelector, currentViewSelector } from './selectors';
import { GlobeMapState, GlobeState, MapState } from './types';

export const actionTypes = {
  setGlobeMapState: 'GLOBE_MAP_SET_STATE',
  setGlobeState: 'GLOBE_MAP_SET_GLOBE_STATE',
  setMapState: 'GLOBE_MAP_SET_MAP_STATE',
  setForceView: 'GLOBE_MAP_SET_FORCE_VIEW',
  setCurrentView: 'GLOBE_MAP_SET_CURRENT_VIEW',
  setAutoRotate: 'GLOBE_MAP_SET_AUTO_ROTATE',
};

export const setGlobeMapState = (state: GlobeMapState) => ({
  type: actionTypes.setGlobeMapState,
  payload: state,
});

export const setGlobeState = (state: GlobeState) => ({
  type: actionTypes.setGlobeState,
  payload: state,
});

export const setMapState = (state: MapState) => ({
  type: actionTypes.setMapState,
  payload: state,
});

export const setForceView = (value: string | null) => ({
  type: actionTypes.setForceView,
  payload: value,
});

export const setCurrentView = (value: string | null) => ({
  type: actionTypes.setCurrentView,
  payload: value,
});

export const setAutoRotate = (value: boolean) => ({
  type: actionTypes.setAutoRotate,
  payload: value,
});

export const toggleCurrentView = () => async (dispatch, getState) => {
  const state = getState();
  const currentView = currentViewSelector(state);
  dispatch(setCurrentView(currentView === 'globe' ? 'map' : 'globe'));
};

export const toggleAutoRotate = () => async (dispatch, getState) => {
  const state = getState();
  const autoRotate = autoRotateSelector(state);
  dispatch(setAutoRotate(!autoRotate));
};
