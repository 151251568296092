export const keyMap = {
  TOGGLE_LAYERS_PANEL: 'shift+v',
  TOGGLE_FILTERS_PANEL: 'shift+f',
  TOGGLE_SETTINGS_PANEL: 'shift+s',
  TOGGLE_INFO_PANEL: 'shift+i',
  TOGGLE_STATS: 'shift+r',
  TOGGLE_SEARCH_PANEL: ['ctrl+space', 'alt+space'],
  TOGGLE_DEMO: 'd',
  FIND_LOCATION: 'shift+l',
  ZOOM_IN: 'pageup',
  ZOOM_OUT: 'pagedown',
};
