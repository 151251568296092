import { cnoise } from './common';

export default `
  #define PI 3.1415926

  uniform sampler2D u_texture;
  uniform float u_time;
  uniform float u_distance;
  uniform vec3 u_tintLow;
  uniform vec3 u_tintHigh;

  varying vec2 vUv;

  ${cnoise}

  float dotMatrix(float x, float y, float distance) {
    float varyX = sin(x * (2000.0 - sin(x * PI) * 10.0) / distance);
    float varyY = cos(y * (1000.0 - sin(y * PI) * 10.0) / distance);
    float dotMatrix = varyX * varyX * varyY * varyY;
    return dotMatrix;
  }

  void main() {
    vec2 uv = vUv;
    vec4 texel = texture2D( u_texture, uv );

    texel.a = texel.b * 2.0;
    float dot = dotMatrix(uv.x, uv.y, u_distance);
    float perlin = cnoise(vec3(uv.x * 1024.0, uv.y * 512.0, u_time * 0.2)) + 0.85;
    vec4 color = texel * texel * dot * 2.0;

    float ratio = texel.r * texel.r * dot * 2.0;

    perlin = perlin * perlin * perlin;

    color.r = mix(u_tintLow.r, u_tintHigh.r * u_tintHigh.r, ratio * perlin);
    color.g = mix(u_tintLow.g, u_tintHigh.g * u_tintHigh.g, ratio * perlin);
    color.b = mix(u_tintLow.b, u_tintHigh.b * u_tintHigh.b, ratio * perlin);

    gl_FragColor = color;
  }
`;
