import { Marker } from 'mapbox-gl';
import millify from 'millify';

import { api, endpoints, gistApi } from 'api';
import { dataDb } from 'api/dbs';
import { MapState } from 'store/structure/GlobeMap/types';
import { isWeb, useMockedData } from 'utils';
import { withEntryPoint } from 'utils/MapBoxEntryPoint';

import { CityProps, MapBoxViewOutProps, MapBoxViewProps } from '../types';
import { colorScale, sizeScaleLog } from '../utils';

import './mapbox-gl.css';
import './mapbox-custom.scss';

export const MapBox = withEntryPoint<MapBoxViewProps, MapBoxViewOutProps>((props) => {
  const { map, updateZoomCb, updatePositionCb, onClickMarker } = props;
  let markers: any[] = [];
  const continentMarkers: any[] = [];
  let addedMarkers: any = {};
  let active = false;
  let continents: any;

  const init = async () => {
    continents = await dataDb.getItem('continents');
    initContinentMarkers();

    map.on('zoom', () => {
      updateZoomCb(map.getZoom());
      updateMarkers();
    });
    map.on('drag', () => {
      updatePositionCb(map.getCenter());
      updateMarkers();
    });
  };

  const destroy = () => {
    map.remove();
  };

  const setZoomAndCoords = ({ zoom, lng, lat }) => {
    map.setZoom(zoom);
    map.setCenter({ lon: lng, lat });
  };

  const moveTo = ({ lng, lat, zoom }) => {
    setTimeout(
      () =>
        map.flyTo({
          center: [lng, lat],
          curve: 0,
        }),
      0
    );
    setTimeout(() => map.zoomTo(zoom), 1000);
  };

  const zoomIn = () => {
    map.zoomIn({ duration: 800 });
  };

  const zoomOut = () => {
    map.zoomOut({ duration: 800 });
  };

  const handleClick = (data) => (e) => {
    e.stopPropagation();
    onClickMarker(data);
  };

  const createMarkerNode = (radius: number, id: any, color: string, title: string, index: number, type) => {
    const el = document.createElement('div');
    const extraClass = type === 'city' && isWeb ? 'circle-marker--is-web' : '';

    el.innerHTML = `<div class="circle-marker ${extraClass}" data-city-id="${id}">
      <div class="circle-marker-tooltip">
        <span>Traffic</span>
        <span class="circle-marker-tooltip-traffic">
          <span class="circle-marker-tooltip-spinner">
            <div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </span>
        </span>
        <span>Hits/s</span>
      </div>
      <div class="circle-marker-container" id="marker-${index}-container">
        <div class="svg-wrapper">
          <svg style="top: ${-(radius + 1)}px; left: ${-(radius + 1)}px; width: ${(radius + 2) * 2}px; height: ${
      (radius + 2) * 2
    }px;">
            <circle
              id="marker-${index}-outer-circle"
              class="outer-circle"
              cx="${radius + 2}" cy="${radius + 2}" r="${radius}"
              data-radius="${radius}"
              stroke="${color}" stroke-width="1px"
              fill="rgba(1, 7, 19, 0.5)"
            "/>
            <circle
              class="inner-circle inner-circle-mod-${index % 20}"
              cx="${radius + 2}" cy="${radius + 2}" r="${2}"
              fill="#fff"
            "/>
          </svg>
        </div>
        <h3 id="marker-${index}-title">${title}</h3>
      </div>
    </div>`;

    return el.children[0];
  };

  const initContinentMarkers = () => {
    const colors = ['#29DDCA', '#FF9933', '#1B9FFF'];
    let i = 0;
    for (const s in continents) {
      const continent = continents[s];
      const el = createMarkerNode(10, continent.id, colors[i % colors.length], continent.name, i, 'continent');
      const marker = new Marker({ element: el as HTMLElement }).setLngLat({ lat: continent.lat, lng: continent.lng });
      marker.getElement().addEventListener('click', handleClick(continent));
      continentMarkers.push(marker);
      i++;
    }
  };

  const showNetworkNodes = (cities: CityProps[]) => {
    const axios = useMockedData ? gistApi : api;
    const endpoint = (id) => (useMockedData ? endpoints.mocks.traffic.city : `${endpoints.traffic.city}/${id}`);

    if (markers.length > 0) {
      markers.forEach(({ marker }) => {
        marker.remove();
      });
      markers = [];
    }

    for (let i = 0; i < cities.length; i++) {
      const city = cities[i];
      const radius: number = sizeScaleLog(cities, 5, 20)(city.capacity + 0.00000001);
      const color: string = colorScale(city.utilization + 0.00000001);
      const el: any = createMarkerNode(radius, city.id, color, city.originalName.toLocaleLowerCase(), i, 'city');

      const marker = new Marker({ element: el as HTMLElement }).setLngLat({ lat: city.lat, lng: city.lng });

      marker.getElement().addEventListener('click', handleClick(city));

      if (isWeb)
        marker.getElement().addEventListener('mouseenter', () => {
          axios.get(endpoint(city.id)).then((response) => {
            try {
              el.querySelector('.circle-marker-tooltip-traffic').innerHTML = millify(response.data.hits);
            } catch (e) {
              console.error(e);
            }
          });
        });

      marker.addTo(map);
      const container = document.getElementById(`marker-${i}-container`);
      const circle = document.getElementById(`marker-${i}-outer-circle`);
      const title = document.getElementById(`marker-${i}-title`);
      markers.push({
        marker,
        element: marker.getElement(),
        container,
        circle,
        title,
        radius,
        lng: city.lng,
        lat: city.lat,
        id: i,
      });
      marker.remove();
    }

    updateMarkers();
  };

  const updateMarkers = () => {
    if (!active) return;

    const zoom = map.getZoom();
    const bounds = map.getBounds();
    const titleOcclusionFactor = (7 - zoom) * 0.3;
    const visibles: any[] = [];

    if (zoom < 3) {
      continentMarkers.forEach((marker) => {
        marker.addTo(map);
      });
      markers.forEach(({ marker }) => {
        marker.remove();
      });
      addedMarkers = {};
      return;
    }

    continentMarkers.forEach((marker) => {
      marker.remove();
    });

    markers.forEach((marker) => {
      if (bounds.contains(marker.marker.getLngLat())) {
        visibles.push(marker);
      } else {
        marker.marker.remove();
        addedMarkers[marker.id] = null;
      }
    });
    if (visibles.length > 0) {
      visibles.sort((a: any, b: any) => {
        return b.radius - a.radius;
      });
      const printed: any[] = [];
      visibles.forEach(({ marker, element, container, circle, title, lng, lat, id }, i) => {
        element.style.zIndex = 10000 - i;
        // container.style.transform = `scale(${Math.max((250 - i) / 250, .1)})`;
        // container.style.opacity = `${Math.max((125 - i) / 125, .15)}`;
        circle.style.transform = `scale(1)`;
        circle.style.opacity = 1;
        title.style.opacity = 0;
        if (i < 25) {
          let collided = false;
          container.style.transform = `none`;
          circle.style.transform = `scale(1)`;
          circle.style.opacity = 1;
          printed.forEach(([prevLng, prevLat]) => {
            if (
              lng > prevLng - titleOcclusionFactor &&
              lng < prevLng + titleOcclusionFactor &&
              lat > prevLat - titleOcclusionFactor &&
              lat < prevLat + titleOcclusionFactor
            ) {
              collided = true;
            }
          });
          if (!collided) {
            title.style.opacity = 1;
            printed.push([lng, lat]);
          }
        } else {
          circle.style.transform = `scale(${Math.max((200 - i) / 200, 0.5)})`;
          // circle.style.opacity = `${Math.max((100 - i) / 100, .5)}`;
          // title.style.opacity = 0;
        }
        if (i < 10000) {
          if (!addedMarkers[id]) {
            marker.addTo(map);
            addedMarkers[id] = true;
          }
        } else {
          marker.remove();
          addedMarkers[id] = false;
        }
      });
    }
  };

  const setActive = (value: boolean) => {
    active = value;
  };

  const getState = (): MapState => {
    const { lng, lat } = map.getCenter();
    return {
      lng,
      lat,
      zoom: map.getZoom(),
    };
  };

  return {
    init,
    destroy,
    setZoomAndCoords,
    showNetworkNodes,
    moveTo,
    zoomIn,
    zoomOut,
    setActive,
    getState,
    updateMarkers,
    map,
  };
});
