import { isMobile } from 'react-device-detect';

import produce from 'immer';
import { get } from 'lodash';

import { actionTypes } from './actions';
import { GlobalSettingsState } from './types';

export const initialState: GlobalSettingsState = {
  showLayersPanel: false,
  showSettingsPanel: false,
  showFiltersPanel: false,
  showInfoPanel: false,
  showDefaultView: true,
  continentMarkersStatus: false,
  showDatasetInfoPanel: !isMobile,
  playDemo: false,
  selectedDataset: 'capacityAndUtilization',
  currentView: 'globe',
  searchPanel: {
    visible: false,
    info: {
      view: 'main',
      displaySelect: false,
    },
  },
  overlayDatasets: {
    populationData: {
      checked: true,
      options: {
        sizePerCapital: { label: 'Size Per Capital', name: 'sizePerCapital', checked: true },
        density: { label: 'Density', name: 'density', checked: false },
      },
    },
  },
  capacityAndUtilization: {
    pointRays: {
      checked: true,
    },
    capacityRange: {
      checked: false,
      range: { min: 0, max: 1000 },
      value: { min: 0, max: 1000 },
    },
    utilizationRange: {
      checked: false,
      range: { min: 0, max: 100 },
      value: { min: 0, max: 100 },
    },
    streamingRegions: {
      checked: true,
    },
    sslRegions: {
      checked: true,
    },
  },
  webApplicationAttacks: {
    connectPointsWithArcs: {
      checked: true,
    },
    cumulativeBubbleMap: {
      checked: false,
    },
  },
  userInterface: {
    hud: {
      checked: true,
      options: {
        currentView: { label: 'Current View', name: 'currentView', checked: true },
        fps: { label: 'FPS', name: 'fps', checked: false },
        chartType: { label: 'Chart Type', name: 'chartType', checked: true },
        settings: { label: 'Settings', name: 'settings', checked: true },
        commentary: { label: 'Commentary', name: 'commentary', checked: true },
        search: { label: 'Search', name: 'search', checked: true },
        demoMode: { label: 'Demo Mode', name: 'demoMode', checked: true },
        timeline: { label: 'Timeline', name: 'timeline', checked: false },
        filters: { label: 'Filters', name: 'filters', checked: true },
        zoomControls: { label: 'Zoom Controls', name: 'zoomControls', checked: true },
      },
    },
    labels: {
      checked: true,
      options: {
        continents: { label: 'Continents', name: 'continents', checked: true },
        cities: { label: 'Cities', name: 'cities', checked: true },
        countries: { label: 'Countries', name: 'countries', checked: true },
        regions: { label: 'Regions', name: 'regions', checked: true },
        states: { label: 'States', name: 'states', checked: true },
      },
    },
    tooltips: {
      checked: true,
    },
    logo: {
      checked: true,
      options: {
        color: { label: 'Color', name: 'color', checked: true },
        white: { label: 'White', name: 'white', checked: false },
      },
    },
  },
  rendering: {
    visualEffects: {
      checked: true,
      options: {
        blur: { label: 'Blur', name: 'blur', checked: true },
        lightning: { label: 'Lightning', name: 'lightning', checked: false },
        codeBackdrop: { label: 'Code Backdrop', name: 'codeBackdrop', checked: true },
        transparency: { label: 'Transparency', name: 'transparency', checked: true },
        code: { label: 'Code', name: 'code', checked: true },
        water: { label: 'Water', name: 'water', checked: true },
      },
    },
  },
  performance: {
    graphicsQuality: {
      checked: true,
      options: {
        auto: { label: 'Auto', name: 'auto', checked: true },
        hd: { label: 'HD', name: 'hd', checked: false },
        sd: { label: 'SD', name: 'sd', checked: false },
        ld: { label: 'LD', name: 'ld', checked: false },
      },
    },
  },
};

export default produce((draft, action) => {
  switch (action.type) {
    case actionTypes.toggleStats:
      draft.userInterface.hud.options.fps.checked =
        typeof action.payload !== 'undefined' ? action.payload : !draft.userInterface.hud.options.fps.checked;
      break;
    case actionTypes.selectDataset:
      draft.selectedDataset = action.payload;
      break;
    case actionTypes.toggleLayersPanel:
      draft.showLayersPanel = typeof action.payload !== 'undefined' ? action.payload : !draft.showLayersPanel;
      break;
    case actionTypes.toggleSettingsPanel:
      draft.showSettingsPanel = typeof action.payload !== 'undefined' ? action.payload : !draft.showSettingsPanel;
      break;
    case actionTypes.toggleFiltersPanel:
      draft.showFiltersPanel = typeof action.payload !== 'undefined' ? action.payload : !draft.showFiltersPanel;
      break;
    case actionTypes.toggleInfoPanel:
      draft.showInfoPanel = typeof action.payload !== 'undefined' ? action.payload : !draft.showInfoPanel;
      break;
    case actionTypes.toggleDefaultView:
      draft.showDefaultView = typeof action.payload !== 'undefined' ? action.payload : !draft.showDefaultView;
      break;
    case actionTypes.toggleDatasetInfoPanel:
      draft.showDatasetInfoPanel = typeof action.payload !== 'undefined' ? action.payload : !draft.showDatasetInfoPanel;
      break;
    case actionTypes.toggleSearch:
      if (draft.searchPanel.visible) draft.searchPanel.info = initialState.searchPanel.info;
      draft.searchPanel.visible = typeof action.payload !== 'undefined' ? action.payload : !draft.searchPanel.visible;
      break;
    case actionTypes.setSearchView:
      draft.searchPanel.info = typeof action.payload !== 'undefined' ? action.payload : initialState.searchPanel.info;
      break;
    case actionTypes.toggleDemo:
      draft.playDemo = typeof action.payload !== 'undefined' ? action.payload : !draft.playDemo;
      break;
    case actionTypes.toggleCheckbox: {
      const onOffToggle = get(draft, action.payload, {});
      onOffToggle.checked = !onOffToggle.checked;
      break;
    }
    case actionTypes.setRangeInput: {
      const range = get(draft, action.payload.path);
      if (range) range.value = action.payload.value;
      break;
    }
    case actionTypes.toggleRadio: {
      const optionsStr = action.payload.match(/.*options/g)[0];
      if (!optionsStr) return;

      const options = get(draft, optionsStr);

      Object.values(options).forEach((item: any) => {
        item.checked = false;
      });

      const onOffToggle = get(draft, action.payload, {});
      onOffToggle.checked = true;
      break;
    }
    case actionTypes.setContinentMarkers: {
      draft.continentMarkersStatus =
        typeof action.payload !== 'undefined' ? action.payload : initialState.searchPanel.info;
    }
  }
}, initialState);
