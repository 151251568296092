import { actionTypes } from '../structure/Data/actions';

export const dataMiddleWare = (store) => (next) => async (action) => {
  const requestSuffix = '_REQUEST';
  const successSuffix = '_SUCCESS';
  const errorSuffix = '_ERROR';

  if (action.type.endsWith(requestSuffix)) {
    store.dispatch({
      type: actionTypes.loading,
      payload: true,
    });
    return next(action);
  }

  if (action.type.endsWith(successSuffix)) {
    store.dispatch({
      type: actionTypes.loading,
      payload: false,
    });
    return next(action);
  }

  if (action.type.endsWith(errorSuffix)) {
    store.dispatch({
      type: actionTypes.loading,
      payload: false,
    });
    return next(action);
  }

  return next(action);
};
