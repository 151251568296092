export default `
  #define PI 3.1415926

  uniform sampler2D u_texture;
  uniform float u_time;
  uniform float u_distance;
  uniform float u_index;
  uniform float u_startTime;
  uniform float u_endTime;
  uniform float u_timeLoop;
  uniform bool u_blur;
  uniform vec3 u_color;

  varying vec2 vUv;

  float dotMatrix(float x, float y, float distance) {
    float varyX = sin(x * 2000.0 / distance);
    float varyY = cos(y * 1000.0 / distance);
    float dotMatrix = varyX * varyX * varyY * varyY;
    return dotMatrix;
  }

  void main() {
    float loopedTime = mod(u_startTime + u_time, u_timeLoop);
    float duration = (u_endTime - u_startTime);
    float timeNormal = loopedTime / duration;

    vec4 texel = texture2D( u_texture, vUv );
    texel.a = texel.b * sin(timeNormal * PI);
    texel.r = u_color.x;
    texel.g = u_color.y;
    texel.b = u_color.z;

    gl_FragColor = texel;
  }
`;
