import React from 'react';

import { StyledFullScreen, StyledGlobeMapWrapper } from './GlobeMapView.styled';
import { useGlobeMapViewLogic } from './useGlobeMapViewLogic';

export const GlobeMapView = () => {
  const { narrow, mapBoxMount, globeMount } = useGlobeMapViewLogic();

  return (
    <StyledGlobeMapWrapper narrow={narrow}>
      <StyledFullScreen ref={mapBoxMount} />
      <StyledFullScreen ref={globeMount} />
    </StyledGlobeMapWrapper>
  );
};
