export const actionTypes = {
  toggleChartVisibility: 'CHART_TOGGLE_VISIBILITY',
  loadChartInfo: 'CHART_LOAD_INFO',
  requestDBData: 'CHART_DATA_REQUEST',
  successDBData: 'CHART_DATA_SUCCESS',
  errorDBData: 'CHART_DATA_ERROR',
};

export const toggleChartVisibility = (visible: boolean) => ({
  type: actionTypes.toggleChartVisibility,
  payload: visible,
});

export const loadChart = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.loadChartInfo, payload: data });
  return dispatch(toggleChartVisibility(true));
};
