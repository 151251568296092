import produce from 'immer';

import { actionTypes as globalSettingsActionTypes } from '../GlobalSettings/actions';
import { actionTypes } from './actions';
import { GlobeMapState } from './types';

const initialState: GlobeMapState = {
  currentView: 'globe',
  forceView: null,
  globe: {
    lng: -40,
    lat: 0,
    zoom: 400,
    autoRotate: true,
  },
  map: {
    lng: 0,
    lat: 0,
    zoom: null,
  },
};

export default produce((draft: GlobeMapState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case actionTypes.setGlobeMapState:
      draft.globe = action.payload.globe;
      draft.map = action.payload.map;
      draft.currentView = action.payload.currentView;
      draft.forceView = action.payload.forceView;
      break;
    case actionTypes.setGlobeState:
      draft.globe = action.payload;
      break;
    case actionTypes.setMapState:
      draft.map = action.payload;
      break;
    case actionTypes.setCurrentView:
      draft.currentView = action.payload;
      break;
    case actionTypes.setForceView:
      draft.forceView = action.payload;
      break;
    case actionTypes.setAutoRotate:
      draft.globe.autoRotate = action.payload;
      break;
    case globalSettingsActionTypes.selectDataset:
      if (action.payload !== 'capacityAndUtilization' && draft.currentView !== 'globe') {
        draft.currentView = 'globe';
      }
      break;
  }
}, initialState);
