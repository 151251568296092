import { createSelector } from 'reselect';

import { StateProps } from '../types';

export const globeMapSelector = (state: StateProps) => state.globeMap;

export const forceViewSelector = createSelector(globeMapSelector, (globeMap) => globeMap.forceView);
export const currentViewSelector = createSelector(globeMapSelector, (globeMap) => globeMap.currentView);
export const globeStateSelector = createSelector(globeMapSelector, (globeMap) => globeMap.globe);
export const mapStateSelector = createSelector(globeMapSelector, (globeMap) => globeMap.map);
export const autoRotateSelector = createSelector(globeMapSelector, (globeMap) => globeMap.globe.autoRotate);
