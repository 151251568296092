import { get } from 'lodash';

import { api, dataDb, endpoints, gistApi } from 'api';
import { isWeb, useMockedData } from 'utils';
import { dbWorker } from 'utils/workers';

import { loadingPercentageSelector } from './selectors';

// Action Creators

export const actionTypes = {
  loading: 'DATA_LOADING',
  updatePercentage: 'DATA_UPDATE_PERCENTAGE',
  request: {
    basic: 'DATA_BASIC_REQUEST',
    images: 'DATA_IMAGES_REQUEST',
    attacks: {
      global: 'DATA_GLOBAL_ATTACKS_REQUEST',
      arcs: 'DATA_ARCS_ATTACKS_REQUEST',
    },
    traffic: {
      globals: 'DATA_GLOBAL_REQUEST',
    },
  },
  success: {
    basic: 'DATA_BASIC_SUCCESS',
    images: 'DATA_IMAGES_SUCCESS',
    attacks: {
      global: 'DATA_GLOBAL_ATTACKS_SUCCESS',
      arcs: 'DATA_ARCS_ATTACKS_SUCCESS',
    },
    traffic: {
      globals: 'DATA_GLOBAL_SUCCESS',
    },
  },
  error: {
    basic: 'DATA_BASIC_ERROR',
    images: 'DATA_IMAGES_ERROR',
    attacks: {
      global: 'DATA_GLOBAL_ATTACKS_ERROR',
      arcs: 'DATA_ARCS_ATTACKS_ERROR',
    },
    traffic: {
      globals: 'DATA_GLOBAL_ERROR',
    },
  },
};

export const fetchResource = (resourceType: string, cb?) => () => async (dispatch) => {
  dispatch({ type: get(actionTypes.request, resourceType) });

  const url = useMockedData ? get(endpoints.mocks, resourceType) : get(endpoints, resourceType);

  try {
    const { data, request }: any = useMockedData ? await gistApi.get(url) : await api.get(url);

    if (request.fromCache) {
      return dispatch({ type: get(actionTypes.success, resourceType) });
    }

    await dataDb.setItem(resourceType, cb ? cb(data) : data);
    dispatch(updateLoadingPercentage());
    return dispatch({ type: get(actionTypes.success, resourceType) });
  } catch (e) {
    console.error(e);
    return dispatch({ type: get(actionTypes.error, resourceType), payload: e });
  }
};

export const fetchArcsAttacks = fetchResource('attacks.arcs', (data) =>
  data.feature_collection.map((f) => ({ ...f.properties, ...f.geometry }))
);

export const fetchGlobalAttacks = fetchResource('attacks.global', (data) => data.types);

export const downloadData = () => async (dispatch) => {
  dispatch({ type: actionTypes.request.basic });
  let servedFromCache = true;

  const mapDataAndCheckIfIsServedFromCache = (response) => {
    if (response.request.fromCache) return null;

    servedFromCache = false;
    return response.data;
  };

  try {
    let data: any = null;

    if (isWeb)
      data = useMockedData
        ? await Promise.all([
            gistApi.get(endpoints.mocks.download.continents).then(mapDataAndCheckIfIsServedFromCache),
            gistApi.get(endpoints.mocks.download.countries).then(mapDataAndCheckIfIsServedFromCache),
            gistApi.get(endpoints.mocks.traffic.cities).then(mapDataAndCheckIfIsServedFromCache),
          ])
        : await Promise.all([
            api.get(endpoints.download.continents).then(mapDataAndCheckIfIsServedFromCache),
            api.get(endpoints.download.countries).then(mapDataAndCheckIfIsServedFromCache),
            api.get(endpoints.traffic.cities).then(mapDataAndCheckIfIsServedFromCache),
          ]);
    else
      data = useMockedData
        ? await Promise.all([
            gistApi.get(endpoints.mocks.download.continents).then(mapDataAndCheckIfIsServedFromCache),
            gistApi.get(endpoints.mocks.download.countries).then(mapDataAndCheckIfIsServedFromCache),
            gistApi.get(endpoints.mocks.download.cities).then(mapDataAndCheckIfIsServedFromCache),
            gistApi.get(endpoints.mocks.download.regions).then(mapDataAndCheckIfIsServedFromCache),
            gistApi.get(endpoints.mocks.download.machines).then(mapDataAndCheckIfIsServedFromCache),
          ])
        : await Promise.all([
            api.get(endpoints.download.continents).then(mapDataAndCheckIfIsServedFromCache),
            api.get(endpoints.download.countries).then(mapDataAndCheckIfIsServedFromCache),
            api.get(endpoints.download.cities).then(mapDataAndCheckIfIsServedFromCache),
            api.get(endpoints.download.regions).then(mapDataAndCheckIfIsServedFromCache),
            api.get(endpoints.download.machines).then(mapDataAndCheckIfIsServedFromCache),
          ]);

    if (!servedFromCache) {
      await dbWorker.saveDataToDb(data);
    }

    dispatch(updateLoadingPercentage());
    dispatch({ type: actionTypes.success.basic });
  } catch (e) {
    console.error(e);
    return dispatch({ type: actionTypes.error.basic, payload: e });
  }
};

export const fetchGlobalTraffic = fetchResource('traffic.globals');

export const updateLoadingPercentage = () => (_dispatch, getState) => {
  const state = getState();
  const loadingPercentage = loadingPercentageSelector(state);

  const preloaderText: HTMLDivElement | null = document.querySelector('.preloader__text');
  if (preloaderText) {
    const subtitle: any = preloaderText.lastChild;
    subtitle.innerHTML = `${loadingPercentage}%`;
  }
};
