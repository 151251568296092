import { combineReducers } from 'redux';
import * as storage from 'redux-storage';

import chart from './Chart/reducer';
import data from './Data/reducer';
import globalSettings from './GlobalSettings/reducer';
import globeMap from './GlobeMap/reducer';

export default () =>
  storage.reducer(
    combineReducers({
      globalSettings,
      data,
      globeMap,
      chart,
    })
  );
