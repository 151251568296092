import { geoInterpolate } from 'd3-geo';
import { scaleLinear, scaleLog } from 'd3-scale';
import * as THREE from 'three';

import { Coordinates, Marker } from './types';

export function coordinatesToPosition(coordinates: Coordinates, radius: number): THREE.Vector3 {
  const [lng, lat] = coordinates;
  const phi = (lat * Math.PI) / 180;
  const theta = ((lng - 180) * Math.PI) / 180;

  const x = -radius * Math.cos(phi) * Math.cos(theta);
  const y = radius * Math.sin(phi);
  const z = radius * Math.cos(phi) * Math.sin(theta);
  return new THREE.Vector3(x, y, z);
}

export function positionToCoordinates(position: THREE.Vector3): Coordinates {
  const sphericalCoords = new THREE.Spherical();
  sphericalCoords.setFromVector3(position);
  const lng = THREE.MathUtils.radToDeg(sphericalCoords.theta);
  const lat = THREE.MathUtils.radToDeg(Math.PI / 2 - sphericalCoords.phi);
  return [lng, lat];
}

export function coordinatesToArc(
  startCoords: Coordinates,
  endCoords: Coordinates,
  radius: number,
  steps = 10
): THREE.Vector3[] {
  const coords: THREE.Vector3[] = [];
  const interpolate = geoInterpolate(startCoords, endCoords);
  for (let i = 0; i < steps; i++) {
    const midCoord = interpolate(i / steps);
    coords.push(coordinatesToPosition([midCoord[0], midCoord[1]], radius));
  }
  return coords;
}

export function clamp(num: number, min: number, max: number): number {
  return num <= min ? min : num >= max ? max : num;
}

export function getArcSplineFromCoords(
  startCoords: Coordinates,
  endCoords: Coordinates,
  radius: number,
  endRadius?: number
) {
  const start = coordinatesToPosition(startCoords, radius);
  const end = coordinatesToPosition(endCoords, endRadius ? endRadius : radius);

  // altitude
  const altitude = clamp(start.distanceTo(end) * 0.75, 20, 40);

  // 2 control points
  const interpolate = geoInterpolate(startCoords, endCoords);
  const midCoord1 = interpolate(0.25);
  const midCoord2 = interpolate(0.75);
  const mid1 = coordinatesToPosition(midCoord1, radius + altitude);
  const mid2 = coordinatesToPosition(midCoord2, radius + altitude);

  return {
    steps: [start, mid1, mid2, end],
    spline: new THREE.CubicBezierCurve3(start, mid1, mid2, end),
  };
}

// https://github.com/stemkoski/stemkoski.github.com/blob/master/Three.js/Earth-LatLon.html
export const greatCircleFunction = (P, Q) => {
  const angle = P.angleTo(Q);
  return function (t: number) {
    const X = new THREE.Vector3()
      .addVectors(P.clone().multiplyScalar(Math.sin((1 - t) * angle)), Q.clone().multiplyScalar(Math.sin(t * angle)))
      .divideScalar(Math.sin(angle));
    return X;
  };
};

export const sizeScaleLog = (markers: Marker[], min: number, max: number) => {
  const capacities: number[] = markers.map((marker) => marker.capacity);
  return (
    scaleLog()
      // No 0 in the domain for logarithmic scales
      .domain([Math.min(...capacities) || 1e-6, Math.max(...capacities)])
      .range([min, max])
  );
};

export const sizeScaleLinear = (markers: Marker[], min: number, max: number) => {
  const capacities: number[] = markers.map((marker) => marker.capacity);
  return (
    scaleLinear()
      // No 0 in the domain for logarithmic scales
      .domain([Math.min(...capacities) || 1e-6, Math.max(...capacities)])
      .range([min, max])
  );
};

export const colorScale = scaleLinear<string>().domain([0, 100]).range(['#FF9933', '#009CDE']);

export const projectShapeToSphere = (shapeGeom, radius) => {
  shapeGeom.vertices.forEach((vert) => {
    const phi = ((90.0 - vert.y) * Math.PI) / 180.0;
    const theta = ((360.0 - vert.x) * Math.PI) / 180.0;
    vert.x = radius * Math.sin(phi) * Math.cos(theta);
    vert.y = radius * Math.cos(phi);
    vert.z = radius * Math.sin(phi) * Math.sin(theta);
  });
};

// https://stackoverflow.com/questions/57743270/how-can-i-display-topojson-with-three-js-basic
export const convertGeoJsonToGeometry = (json) => {
  const shapes: THREE.Shape[] = [];

  let coordinates = json.geometry.coordinates;
  // The code originally only supported MultiPolygons, this effectively converts
  // Polygons into them.
  if (json.geometry.type === 'Polygon') {
    coordinates = [coordinates];
  }
  for (const coordinate of coordinates) {
    // contour
    const points: THREE.Vector2[] = [];
    const contour = coordinate[0];
    for (const point of contour) {
      points.push(new THREE.Vector2(point[0], point[1]));
    }
    const shape = new THREE.Shape(points);
    // hole
    const hole = coordinate[1];
    if (hole) {
      const path = new THREE.Path();
      for (let i = 0; i < hole.length; i++) {
        const point = hole[i];
        if (i === 0) {
          path.moveTo(point[0], point[1]);
        } else {
          path.lineTo(point[0], point[1]);
        }
      }
      shape.holes.push(path);
    }
    shapes.push(shape);
  }

  const geometry = new THREE.ExtrudeGeometry(shapes, {
    bevelEnabled: false,
  });

  return geometry;
};
