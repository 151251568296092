import { isWeb } from '../utils';

export const publicBaseURL = 'https://gnet.akamai.com/api/v1';
export const privateBaseURL = 'https://gnet.akamai.com/api/v1'; // 'http://20.75.136.180';
// export const publicBaseURL = 'http://localhost:3005';
// export const privateBaseURL = 'http://localhost:3005'; // 'http://20.75.136.180';
// export const publicBaseURL = 'https://gnet-nocc.akamai.com/services/api/v1';
// export const privateBaseURL = 'https://gnet-nocc.akamai.com/services/api/v1';
// export const publicBaseURL = 'https://gnet-nocc-stage.akamai.com/services/api/v1';
// export const privateBaseURL = 'https://gnet-nocc-stage.akamai.com/services/api/v1';
export const baseURL = isWeb ? publicBaseURL : privateBaseURL;

export const gistURL = 'https://gist.githubusercontent.com/valentinvoilean';
export const apiKey = '2QPjdR8HYsaXCSKj';
export const DATA_REFRESH_INTERVAL = 2 * 60 * 60 * 1000; // 2 hour
export const CACHE_MAX_AGE = 10 * 60 * 1000; // 10 minutes

export const ATTACK_MAX_ZOOM_LEVEL = 330;
export const ATTACK_SCREEN = 'webAppAttacks';
export const TRAFFIC_SCREEN = 'capacityAndUtilization';
