// Create `localforage` instance
import localforage from 'localforage';

export const apiDb = localforage.createInstance({
  driver: [localforage.INDEXEDDB, localforage.LOCALSTORAGE],
  name: 'akm-cache',
});

export const dataDb = localforage.createInstance({
  driver: [localforage.INDEXEDDB],
  name: 'akm-data',
});
