import * as THREE from 'three';

import globeImageBlurUrl from 'assets/textures/earth-land-4k-blur.jpg';
import globeImageUrl from 'assets/textures/earth-land-4k.jpg';

import fDotMatrixShader from '../shaders/fDotMatrixShader';
import vPassThroughShader from '../shaders/vPassThroughShader';

class Earth {
  backGlobeMesh: THREE.Mesh<THREE.SphereBufferGeometry, THREE.Material>;
  globeMesh: THREE.Mesh<THREE.SphereBufferGeometry, THREE.ShaderMaterial>;
  glowMesh: THREE.Mesh<THREE.SphereBufferGeometry, THREE.Material>;
  container: THREE.Object3D;

  constructor(parameters: { radius: number; segments: number }) {
    const loader = new THREE.TextureLoader();

    const globeGeometry = new THREE.SphereBufferGeometry(parameters.radius, parameters.segments, parameters.segments);
    const globeTexture = loader.load(globeImageUrl);
    const globeBlurTexture = loader.load(globeImageBlurUrl);

    const uniforms = {
      u_time: {
        value: 0,
      },
      u_distance: { type: 'f', value: 400 },
    };

    // create the material for the front object
    const shaderMaterial = new THREE.ShaderMaterial({
      uniforms: {
        ...uniforms,
        u_texture: { value: globeTexture },
        u_tintLow: { value: new THREE.Color(0x020d1c) },
        u_tintHigh: { value: new THREE.Color(0x9fdef8) },
      },
      vertexShader: vPassThroughShader,
      fragmentShader: fDotMatrixShader,
      transparent: true,
      side: THREE.FrontSide,
    });

    const backShaderMaterial = new THREE.MeshBasicMaterial({
      map: globeBlurTexture,
      transparent: true,
      opacity: 1,
      side: THREE.BackSide,
    });

    // The order is important here for some reason...
    this.backGlobeMesh = new THREE.Mesh(globeGeometry, backShaderMaterial);
    this.globeMesh = new THREE.Mesh(globeGeometry, shaderMaterial);
    this.container = new THREE.Object3D();

    const glowMaterial = new THREE.MeshPhongMaterial({
      color: 0x020d1d,
      blending: THREE.AdditiveBlending,
      opacity: 0.5,
      transparent: true,
      shininess: 10,
      side: THREE.FrontSide,
    });

    this.glowMesh = new THREE.Mesh(globeGeometry, glowMaterial);

    this.container.add(this.backGlobeMesh);
    this.container.add(this.globeMesh);
    this.container.add(this.glowMesh);
  }

  animationLoop(camera: THREE.Camera, clock: THREE.Clock) {
    this.globeMesh.material.uniforms.u_time.value = clock.getElapsedTime();
    this.globeMesh.material.uniforms.u_distance.value = camera.position.distanceTo(new THREE.Vector3(0, 0, 0)) / 250;
  }
}

export default Earth;
