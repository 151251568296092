import { getOr } from 'lodash/fp';
import { createSelector } from 'reselect';

import { StateProps } from '../types';
import { initialState } from './reducer';

export const chartSelector = (state: StateProps) => state.chart;

export const chartVisibilitySelector = createSelector(chartSelector, getOr(false, 'visible'));

export const chartInfoSelector = createSelector(chartSelector, getOr(initialState.info, 'info'));

export const loadedDBDataSelector = createSelector(chartSelector, getOr(false, 'dbDataLoaded'));
