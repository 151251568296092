import React from 'react';
import { useSelector } from 'react-redux';

import { LogoFullColor, LogoWhite } from 'assets/logos';
import { logoColorSelector, logoVisibilitySelector } from 'store/structure/GlobalSettings/selectors';

import { StyledLogo } from './Logo.styled';

export const Logo = () => {
  const logoColor = useSelector(logoColorSelector);
  const isLogoVisible = useSelector(logoVisibilitySelector);

  return isLogoVisible ? <StyledLogo>{logoColor === 'color' ? <LogoFullColor /> : <LogoWhite />}</StyledLogo> : null;
};
