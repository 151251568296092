import { useEffect, useRef } from 'react';

import { debounce } from 'lodash';

import { DATA_REFRESH_INTERVAL } from 'api/constants';
import { isEnvDevelopment } from 'utils';

declare global {
  interface Window {
    lastTimeAppActive: Date;
  }
}

export const useAppRefresh = () => {
  // const searchParams = new URLSearchParams(document.location.search);
  // const view = searchParams.get('view');

  const timeout = useRef<any>(null);

  const destroyTimeout = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }
  };

  const createTimeout = (msToHour) => {
    timeout.current = setTimeout(() => {
      const now = new Date();
      const diff = now.getTime() - window.lastTimeAppActive.getTime();
      console.log('Last activity since (in minutes)', diff / 1000 / 60);
      if (diff / 1000 / 60 < 30) {
        // if the user interacted with the app less than 30 minutes ago, postpone the app reload for 1 hour
        const extendTimer = DATA_REFRESH_INTERVAL / 2;
        console.log('Extending reload for', extendTimer / 1000 / 60, 'minutes');
        createTimeout(extendTimer);
        return;
      }

      let counter = 0;
      const statusInterval = setInterval(() => {
        try {
          const isDBWorkerBusy = window.localStorage.getItem('isDBWorkerBusy') || 'false';
          // Check if the DBWorker is busy.
          if (!JSON.parse(isDBWorkerBusy)) {
            window.localStorage.setItem('isDBWorkerBusy', 'true');
            // Clearing interval might not be required.
            clearInterval(statusInterval);
            window.location.reload();
          }
        } catch (err) {
          console.error(err);
        }

        if (counter > 30) {
          window.localStorage.setItem('isDBWorkerBusy', 'false');
          // Clearing interval might not be required.
          clearInterval(statusInterval);
          window.location.reload();
        }

        counter += 1;
      }, 1000);
    }, msToHour);
  };

  const resetEveryHour = () => {
    destroyTimeout();
    createTimeout(DATA_REFRESH_INTERVAL);
  };

  const setLastTimeAppActive = debounce(() => {
    window.lastTimeAppActive = new Date();

    // Testing... Remove the following 1 line later.
    console.info('Last Time Interaction: ', window.lastTimeAppActive);
    if (isEnvDevelopment) {
      console.info('Last Time Interaction: ', window.lastTimeAppActive);
    }
  }, 1000);

  useEffect(() => {
    resetEveryHour();

    window.addEventListener('mousemove', setLastTimeAppActive);
    window.addEventListener('resize', setLastTimeAppActive);

    return () => {
      window.removeEventListener('mousemove', setLastTimeAppActive);
      window.removeEventListener('resize', setLastTimeAppActive);
      destroyTimeout();
    };
  }, []);
};
