import React from 'react';

import { StyledSpinner, StyledSpinnerWrapper } from './Spinner.styled';
import { SpinnerProps } from './types';

export const Spinner = ({ color = 'white', scale = 1, ...rest }: SpinnerProps) => (
  <StyledSpinnerWrapper scale={scale} {...rest}>
    <StyledSpinner color={color}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </StyledSpinner>
  </StyledSpinnerWrapper>
);
