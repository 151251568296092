import { isEnvDevelopment } from 'utils';

export const appRefreshMiddleware = () => (next) => async (action) => {
  window.lastTimeAppActive = new Date();

  if (isEnvDevelopment) {
    console.info('Last Time Interaction: ', window.lastTimeAppActive);
  }

  return next(action);
};
