import produce from 'immer';

import { actionTypes } from './actions';
import { DataState } from './types';

export const initialState: DataState = {
  loaded: {
    basic: false,
    arcsAttacks: false,
    globalAttacks: false,
    images: false,
    globalTraffic: false,
  },
  loading: false,
  percentage: 0,
};

export default produce((draft, action) => {
  switch (action.type) {
    case actionTypes.success.basic:
      draft.loaded.basic = true;
      draft.percentage += 20;
      break;
    case actionTypes.success.attacks.arcs:
      draft.loaded.arcsAttacks = true;
      draft.percentage += 20;
      break;
    case actionTypes.success.attacks.global:
      draft.loaded.globalAttacks = true;
      draft.percentage += 20;
      break;
    case actionTypes.success.images:
      draft.loaded.images = true;
      draft.percentage += 20;
      break;
    case actionTypes.success.traffic.globals:
      draft.loaded.globalTraffic = true;
      draft.percentage += 20;
      break;
    case actionTypes.loading:
      draft.loading = action.payload;
      break;
  }
}, initialState);
