import styled from 'styled-components';

export const StyledGlobeMapWrapper = styled.div<any>`
  position: relative;
  width: 100%;
  height: 100%;
  float: right;

  @media screen and (min-width: 1024px) {
    width: ${({ narrow }) => (narrow ? 75 : 100)}%;
  }

  @media screen and (min-width: 1600px) {
    width: ${({ narrow }) => (narrow ? 77 : 100)}%;
  }
`;

export const StyledFullScreen = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
