import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';

import { apiKey, gistURL } from './constants';
import { CACHE_MAX_AGE } from './constants';
import { apiDb } from './dbs';

// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: CACHE_MAX_AGE,
  exclude: { query: false }, // cache requests with query parameters
  store: apiDb, // pass `localforage` store to `axios-cache-adapter`
});

export const api = axios.create({
  headers: { Authorization: 'Bearer ' + apiKey },
  adapter: cache.adapter,
});

export const gistApi = axios.create({
  baseURL: gistURL,
  adapter: cache.adapter,
});

export const imagesApi = axios.create({
  adapter: cache.adapter,
});
